import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-power-range',
  templateUrl: './power-range.component.html',
  styleUrls: ['./power-range.component.scss'],
})
export class PowerRangeComponent implements OnChanges {
  @Input() selectedRange: { lower: number; upper: number };
  knobValues: { lower: number; upper: number };
  valueArray: number[] = [2, 7, 22, 50, 150, 350];
  @Output() filterEvent = new EventEmitter<{ lower: number; upper: number }>();

  constructor() { }

  ngOnChanges() {
    this.knobValues = { lower: 0, upper: this.valueArray.length - 1 };
    if (this.selectedRange) {
      this.knobValues = {
        lower: this.valueArray.findIndex((value) => value === this.selectedRange.lower),
        upper: this.valueArray.findIndex((value) => value === this.selectedRange.upper),
      };
    }
  }

  selectRange() {
    this.selectedRange = {
      lower: this.valueArray[this.knobValues.lower],
      upper: this.valueArray[this.knobValues.upper],
    };
    this.filterEvent.emit(this.selectedRange);
  }
}
