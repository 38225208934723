import { CdcAuthComponent } from "./components/master-detail-cdc-authorization/cdc-auth.component";
import { UserProfileComponent } from "./components/master-detail-user-profile/user-profile.component";
import { AppInfoComponent } from "./components/master-details-app-info/app-info.component";
import { RfidDetailsComponent } from "./components/master-details-rfid/rfid-details.component";
import { LoginPage } from "./pages/login/login.page";

export interface MenuItem { iconName: string, title: string, cssClass: string, component: object, needsToBeLogged: boolean }
export const menuItems: MenuItem[] = [
  {
    iconName: 'person-outline',
    title: 'MENU.PROFILE',
    cssClass: 'yf-master-detail-modal',
    component: UserProfileComponent,
    needsToBeLogged: true
  },
  /*{
    iconName: 'calendar-outline',
    title: 'Sessioni',
    cssClass: 'yf-master-detail-modal',
    component: SessionsComponent,
    needsToBeLogged: true
  },*/
  {
    iconName: 'cash-outline',
    title: 'MENU.PAYMENT_METHOD',
    cssClass: 'yf-master-detail-modal',
    component: CdcAuthComponent,
    needsToBeLogged: true
  },
  {
    iconName: 'id-card-outline',
    title: 'MENU.RFID_TAG',
    cssClass: 'yf-master-detail-modal',
    component: RfidDetailsComponent,
    needsToBeLogged: true
  },
  /* {
    iconName: 'notifications-outline',
    title: 'Notifiche',
    cssClass: 'yf-master-detail-modal',
    component: NotificationDetailsComponent,
    needsToBeLogged: true
  }, */
  {
    iconName: 'exit-outline',
    title: 'MENU.LOGIN',
    cssClass: 'login-modal',
    component: LoginPage,
    needsToBeLogged: false
  },
  {
    iconName: 'information-circle-outline',
    title: 'MENU.INFO',
    cssClass: 'yf-master-detail-modal',
    component: AppInfoComponent,
    needsToBeLogged: undefined
  },
  {
    iconName: 'exit-outline',
    title: 'MENU.LOGOUT',
    cssClass: 'yf-master-detail-modal',
    component: null,
    needsToBeLogged: true
  },
];