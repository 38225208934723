// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.unpaid-banner ion-card {
  color: #fff;
  background-color: rgb(255, 0, 0) !important;
  border-top-left-radius: var(--yf-spacing1);
  border-top-right-radius: var(--yf-spacing1);
  padding: 16px;
  cursor: pointer;
}
.unpaid-banner ion-card-header {
  padding-left: 0px;
  padding-top: 0px;
  padding-bottom: 12px;
}
.unpaid-banner ion-card-title {
  color: #fff;
}
.unpaid-banner ion-card-content {
  width: 100%;
  padding-top: 0px;
  padding-left: 0px;
  padding-bottom: 16px;
}
.unpaid-banner ion-button {
  --color: #fff;
  --border-color: #fff;
  --border-style: solid;
  --border-width: 1px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/unpaid-session-banner/unpaid-session-banner.component.scss"],"names":[],"mappings":"AACE;EACE,WAAA;EACA,2CAAA;EACA,0CAAA;EACA,2CAAA;EACA,aAAA;EACA,eAAA;AAAJ;AAEE;EACC,iBAAA;EACA,gBAAA;EACA,oBAAA;AAAH;AAEE;EACE,WAAA;AAAJ;AAEE;EACE,WAAA;EACA,gBAAA;EACA,iBAAA;EACA,oBAAA;AAAJ;AAEE;EACE,aAAA;EAEA,oBAAA;EACA,qBAAA;EACA,mBAAA;AADJ","sourcesContent":[".unpaid-banner{\n  ion-card {\n    color: #fff;\n    background-color: rgb(255, 0, 0) !important;\n    border-top-left-radius: var(--yf-spacing1);\n    border-top-right-radius: var(--yf-spacing1);\n    padding: 16px;\n    cursor: pointer;\n  }\n  ion-card-header{\n   padding-left: 0px;\n   padding-top: 0px;\n   padding-bottom: 12px;\n  }\n  ion-card-title{\n    color: #fff;\n  }\n  ion-card-content {\n    width: 100%;\n    padding-top: 0px;\n    padding-left: 0px;\n    padding-bottom: 16px;\n  }\n  ion-button {\n    --color: #fff;\n  \n    --border-color: #fff;\n    --border-style: solid;\n    --border-width: 1px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
