// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sid {
  font-size: 8px;
  color: var(--ion-color-medium);
  padding: 0px;
  text-align: center;
}

.flex-80 {
  flex: 80%;
}`, "",{"version":3,"sources":["webpack://./src/app/components/station/station-buttons/station-buttons.component.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,8BAAA;EACA,YAAA;EACA,kBAAA;AACJ;;AACE;EACE,SAAA;AAEJ","sourcesContent":[".sid {\n    font-size: 8px;\n    color: var(--ion-color-medium);\n    padding: 0px;\n    text-align: center;\n  }\n  .flex-80{\n    flex: 80%;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
