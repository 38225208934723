import { Injectable } from '@angular/core';
import { State, StateValues } from 'common_library';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class UiService {
  constructor(
    private translate: TranslateService) { }

  getTextColor(isMine: boolean): string {
    return isMine ? 'light' : 'secondary';
  }

  getStateBackground(isMine: boolean) {
    return { background: isMine ? 'var(--ion-color-secondary)' : 'var(--ion-color-light)' };
  }

  getConnectorStateClass(isMine: boolean, state: StateValues) {
    const classes = {
      prenotato: isMine && state === State.RESERVED,
      inCarica: isMine && state === State.CHARGING,
      occupazione: isMine && state === State.OCCUPIED,
      disponibile: !isMine && state === State.AVAILABLE,
      reservedNotMine: !isMine && state === State.RESERVED,
      chargingNotMine: !isMine && state === State.CHARGING,
      occupiedNotMine: !isMine && state === State.OCCUPIED,
      unavailable: !isMine && state === State.UNAVAILABLE
    };
    return classes;
  }

  getStationStateClass(state: StateValues) {
    const classes = {
      disponibile: state === State.AVAILABLE,
      inCarica: state === State.CHARGING || state === State.RESERVED || state === State.OCCUPIED,
      unavailable: state === State.UNAVAILABLE
    };
    return classes;
  }

  getStateLabel(state: StateValues): string {
    switch (state) {
      case State.CHARGING:
        return this.translate.instant("APP.INSTALLATION.STATE_CHARGING");
      case State.OCCUPIED:
        return this.translate.instant("APP.INSTALLATION.STATE_SESSION_OCCUPIED");
      case State.RESERVED:
        return this.translate.instant("APP.INSTALLATION.STATE_RESERVED");
      case State.AVAILABLE:
        return this.translate.instant("APP.INSTALLATION.STATE_AVAILABLE");
      case State.UNAVAILABLE:
        return this.translate.instant("APP.INSTALLATION.STATE_UNAVAILABLE");
      default:
        return "";
    }
  }

  detailedGetStateLabel(state: StateValues): string {
    switch (state) {
      case State.CHARGING:
        return this.translate.instant("APP.INSTALLATION.STATE_CHARGING_DETAILED");
      case State.OCCUPIED:
        return this.translate.instant("APP.INSTALLATION.STATE_SESSION_ENDED_DETAILED");
      case State.RESERVED:
        return this.translate.instant("APP.INSTALLATION.STATE_RESERVE_ENDING_DETAILED");
      case State.AVAILABLE:
        return this.translate.instant("APP.INSTALLATION.STATE_AVAILABLE");
      case State.UNAVAILABLE:
        return this.translate.instant("APP.INSTALLATION.STATE_UNAVAILABLE");
      default:
        return "";
    }
  }
}
