import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'yf-star-container',
  templateUrl: './star-container.component.html',
  styleUrls: ['./star-container.component.scss'],
})
export class StarContainerComponent implements OnInit {
  @Input() isClickable: boolean = false;
  @Input() rating: number;
  @Input() color: string;
  stars = [1, 2, 3, 4, 5];
  hoverState = 0;

  constructor() {}

  ngOnInit() {}

  onStarEnter(star: number) {
    this.hoverState = star;
  }
  onStarLeave() {
    this.hoverState = 0;
  }
  onStarClicked(star: number) {
    this.rating = star;
  }
}
