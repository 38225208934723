// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title {
  font-size: 12px;
  color: var(--ion-color-medium);
  padding-top: 8px;
}

.slide-container {
  position: relative;
  min-height: 60px;
}

.icon-container {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0), #fff 30%);
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  right: 0;
  bottom: 0;
  height: auto;
  width: 90px;
  z-index: 998;
}

.btnAdd {
  --box-shadow: transparent;
}

.image {
  border-radius: 6px;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}`, "",{"version":3,"sources":["webpack://./src/app/components/photos/photos.component.scss"],"names":[],"mappings":"AAGA;EACE,eAAA;EACA,8BAAA;EACA,gBAAA;AAFF;;AAIA;EACE,kBAAA;EACA,gBAAA;AADF;;AAGA;EACE,oEAAA;EACA,kBAAA;EACA,aAAA;EACA,yBAAA;EACA,mBAAA;EACA,QAAA;EACA,SAAA;EACA,YAAA;EACA,WAAA;EACA,YAAA;AAAF;;AAEA;EACE,yBAAA;AACF;;AACA;EACE,kBAAA;EACA,cAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;EACA,uBAAA;AAEF","sourcesContent":["// .thumbnailContainer {\n//   width: 100%;\n// }\n.title {\n  font-size: 12px;\n  color: var(--ion-color-medium);\n  padding-top: 8px;\n}\n.slide-container {\n  position: relative;\n  min-height: 60px;\n}\n.icon-container {\n  background: linear-gradient(90deg, rgba(255, 255, 255, 0), #fff 30%);\n  position: absolute;\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n  right: 0;\n  bottom: 0;\n  height: auto;\n  width: 90px;\n  z-index: 998;\n}\n.btnAdd {\n  --box-shadow: transparent;\n}\n.image {\n  border-radius: 6px;\n  display: block;\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n  object-position: center;\n}\n\n// .detailContainer {\n//   width: 100%;\n//   display: flex;\n//   flex-direction: row;\n//   justify-content: space-between;\n//   align-items: center;\n//   .infoPhoto {\n//     display: flex;\n//     flex-direction: column;\n//     justify-content: flex-start;\n//     align-items: flex-start;\n//     ion-label {\n//       font-size: 12px;\n//     }\n//   }\n//   .btnsContainer {\n//     display: flex;\n//     flex-direction: column;\n//     justify-content: flex-end;\n//     align-items: flex-end;\n//   }\n// }\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
