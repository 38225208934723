import { Injectable } from '@angular/core';
import { HttpIoService } from '../communication/http-io.service';
import { IInfoChapter, IInfoContent } from 'common_library';
import { AuthService } from '../auth/auth.service';
import { MainStateService } from '../state/app-main-state.service';

const CONTROLLER_ROOT = 'info-content';

@Injectable({
  providedIn: 'root'
})
export class InfoService {

  constructor(
    private mainStateService: MainStateService,
    private HIO: HttpIoService,
    private auth: AuthService
  ) { }

  async getCmsContent() {
    const user = this.mainStateService.$user();
    let res: IInfoContent;
    if (user?.id) {
      res = await this.HIO.get(`${CONTROLLER_ROOT}/content-by-user/${user?.id}`);
    } else {
      res = await this.HIO.get(`${CONTROLLER_ROOT}/content-by-user`);
    }
    if (res) {
      res.infoChapters.forEach(element => {
        this.populateSections(element);
      });
      this.mainStateService.setCmsContent(res);
    }
    else {
      this.mainStateService.setCmsContent(null);
    }
  }

  public populateChapterComposition(chapter: IInfoChapter): string {
    if (chapter) {
      const sections: any[] = chapter.infoSections;
      let chapterComposition = [];
      for (let i = 0; i < sections.length; i++) {
        const section = sections[i];
        let publishedVersion = section.infoSectionVersion.find(el => el.isPublished === true)?.sectionVersion;
        if (!publishedVersion) {
          publishedVersion = 0;
        }
        chapterComposition.push(`${section.id}|${publishedVersion}`);
      }
      return chapterComposition.join(",");
    }
    return "";
  }

  private populateSections(chapter: IInfoChapter) {
    const sections: any[] = chapter.infoSections;
    for (let j = 0; j < sections.length; j++) {
      sections[j].html = sections[j].infoSectionVersion[0]?.description?.replace('<p>', '<ion-text>').replace('</p>', '</ion-text>');
    }
  }
}
