// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-card {
  margin: 0;
  padding: 0px;
  border-radius: 0px;
  --color: var(--ion-color-medium);
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/car-details/car-details.component.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;EACA,YAAA;EACA,kBAAA;EAEA,gCAAA;EACA,0BAAA;EACA,uBAAA;EACA,sBAAA;EACA,qBAAA;AAAF","sourcesContent":["ion-card {\n  margin: 0;\n  padding: 0px;\n  border-radius: 0px;\n  //box-shadow: var(--yf-shadow) !important;\n  --color: var(--ion-color-medium);\n  -webkit-border-radius: 0px;\n  -moz-border-radius: 0px;\n  -ms-border-radius: 0px;\n  -o-border-radius: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
