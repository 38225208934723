// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-container {
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--yf-white);
  color: black;
}

ion-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

ion-card {
  width: 100px;
  height: 80px;
  padding: 0px;
  margin: 4px;
  background-color: #174C6D;
  box-shadow: var(--yf-shadow);
}

ion-card-content {
  width: 100%;
  padding: 0;
  height: 55px;
}

.yf-container {
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/components/cost-detail/cost-card/cost-card.component.scss"],"names":[],"mappings":"AAGA;EACE,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,iCAAA;EACA,YAAA;AAFF;;AAKA;EACE,gBAAA;EACA,uBAAA;EACA,mBAAA;AAFF;;AAKA;EACE,YAAA;EACA,YAAA;EACA,YAAA;EACA,WAAA;EACA,yBAAA;EACA,4BAAA;AAFF;;AAKA;EACE,WAAA;EACA,UAAA;EACA,YAAA;AAFF;;AAKA;EACE,YAAA;AAFF","sourcesContent":["// .card{\n//   background-color: #174C6D;\n// }\n.text-container {\n  height: 24px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background-color: var(--yf-white);\n  color: black;\n\n}\nion-text {\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n\nion-card {\n  width: 100px;\n  height: 80px;\n  padding: 0px;\n  margin: 4px;\n  background-color: #174C6D;\n  box-shadow: var(--yf-shadow);\n}\n\nion-card-content {\n  width: 100%;\n  padding: 0;\n  height: 55px;\n}\n\n.yf-container{\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
