// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.loading-container .loading-text {
  padding-top: 20px;
  font-size: 16px;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/app/components/master-detail-cdc-authorization/components/cdc-waiting/cdc-waiting.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,eAAA;EACA,uBAAA;AACF;AACE;EACE,iBAAA;EACA,eAAA;EACA,kBAAA;AACJ","sourcesContent":[".loading-container {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n\n  .loading-text {\n    padding-top: 20px;\n    font-size: 16px;\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
