// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.swiper {
  max-height: calc(100vh - 60px - 24px);
  position: fixed;
  top: calc(env(safe-area-inset-top) + 72px);
  z-index: 1;
}
@media (max-width: 576px) {
  .swiper {
    width: 100vw;
  }
}
@media (min-width: 576px) {
  .swiper {
    width: 380px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/pages/session-toast/session-toast.component.scss","webpack://./src/theme/sass/abstracts/mixins.scss"],"names":[],"mappings":"AAEA;EAQE,qCAAA;EACA,eAAA;EACA,0CAAA;EACA,UAAA;AARF;ACWI;EDdJ;IAEI,YAAA;EAKF;AACF;ACMI;EDdJ;IAKI,YAAA;EAOF;AACF","sourcesContent":["@import './../../../theme/sass/abstracts/mixins.scss';\n\n.swiper { \n  @include breakpoint(sm, max) {\n    width: 100vw;\n  }\n  @include breakpoint(sm, min) {\n    width: 380px;\n  }\n\n  max-height: calc(100vh - 60px - 24px);\n  position: fixed;\n  top: calc(env(safe-area-inset-top) + 72px);\n  z-index: 1;\n}\n","$breakpoints: (\n  xxs: 375px,\n  xs: 450px,\n  sm: 576px,\n  md: 768px,\n  lg: 992px,\n  xl: 1200px,\n);\n\n// mobile-first breakpoint mixin\n@mixin breakpoint($breakpoint: md, $key: min, $orientation: false) {\n  @if ($orientation) {\n    @media (#{$key}-width: map-get($breakpoints, $breakpoint)) and (orientation : $orientation) {\n      @content;\n    }\n  } @else {\n    @media (#{$key}-width: map-get($breakpoints, $breakpoint)) {\n      @content;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
