import { Component, computed, input, Input } from '@angular/core';
import { IStation } from 'common_library';
import { CoreService } from 'src/app/services/core.service';
import { UiService } from 'src/app/services/utils/ui.service';

@Component({
  selector: 'yf-state-simple',
  templateUrl: './state-simple.component.html',
  styleUrls: ['./state-simple.component.scss'],
})
export class StateSimpleComponent {
  $station = input<IStation>(null);
  
  @Input() showIcon?: boolean = true;
  @Input() isInstallation?: boolean = false

  $stationClass = computed(() => {
    return this.UI.getStationStateClass(this.$station()?.state);
  });

  $stateLabel = computed(() => {
    return this.UI.getStateLabel(this.$station()?.state);
  });

  constructor(public UI: UiService, public CS: CoreService) {
  }
}
