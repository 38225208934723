// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section-img {
  display: flex;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/app/components/modal-chapter-updated/modal-chapter-updated.component.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,uBAAA;AADF","sourcesContent":["@import './../../../theme/sass/abstracts/mixins.scss';\n\n.section-img {\n  display: flex;\n  justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
