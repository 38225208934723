import { Injectable, OnInit } from '@angular/core';
import { ICar, ICreateCarDTO, IUpdateCarDTO, PlugTypeValues } from 'common_library';
import { HttpIoService } from '../communication/http-io.service';
import { MainStateService } from '../state/app-main-state.service';
import { BehaviorSubject } from 'rxjs';

const CONTROLLER_ROOT = 'car';

@Injectable({ providedIn: 'root' })
export class CarService implements OnInit {

  newVehicle: ICar | any;

  $simpleUserCars = new BehaviorSubject<any[]>([]);


  constructor(private HIO: HttpIoService,
    private mainStateService: MainStateService) {
  }
  
  ngOnInit(): void {
    this.populateSimpleCars();
  }

  async fetchCars(): Promise<ICar[]> {
    try {
      let rv: ICar[] = [];
      if (this.mainStateService.getUser()) {
        rv = await this.HIO.fetchItems<ICar>(CONTROLLER_ROOT);
      }
      this.mainStateService.setUserCars(rv);
      this.populateSimpleCars();
      return rv;
    } catch (error) {
      console.log('🐱️ : error', error);
    }
  }

  async selectCar(id: number): Promise<void> {
    try {
      await this.HIO.getWithoutResponse(`${CONTROLLER_ROOT}/select/${id}`);
      await this.fetchCars();
    } catch (error) {
      console.log('🐱️ : error', error);
    }
  }

  async add(modelId: number, plugTypes: PlugTypeValues[]): Promise<ICar> {
    try {
      const dto: ICreateCarDTO = { modelId, plugTypes, isSelected: false };
      const car = await this.HIO.postItem<ICar, ICreateCarDTO>(CONTROLLER_ROOT, dto);
      const cars = await this.fetchCars();
      return cars.find((c) => c.id === car.id);
    } catch (error) {
      console.log('🐱️ : error', error);
    }
  }

  async update(carId: number, dto: IUpdateCarDTO): Promise<ICar> {
    try {
      const car = await this.HIO.patchItem<ICar, IUpdateCarDTO>(CONTROLLER_ROOT, carId, dto);
      const cars = await this.fetchCars();
      return cars.find((c) => c.id === car.id);
    } catch (error) {
      console.log('🐱️ : error', error);
    }
  }

  async delete(carId: number): Promise<boolean> {
    try {
      const deleted = await this.HIO.deleteItem(CONTROLLER_ROOT, carId);
      if (deleted) {
        await this.fetchCars();
      }
      return deleted;
    } catch (error) {
      console.log('🐱️ : error', error);
      return false;
    }
  }

  // N.B: sarebbe stata perfetta una computed che reagisse a userCars, ma formly non le gestisce
  populateSimpleCars() {
    const uc = this.mainStateService.$userCars()?.map((c) => ({
      id: c.id,
      userId: c.userId,
      name: `${c.model.brand.name} ${c.model.name}`
    }));
    this.$simpleUserCars.next(uc);
  }
}
