import { Injectable } from '@angular/core';
import { AlertButton, AlertController, IonicSafeString } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  public alert: HTMLIonAlertElement = null;

  constructor(public alertCtrl: AlertController, private translate: TranslateService) { }

  async presentYfAlert(opt: {
    header: string;
    message: string;
    buttons: (string | AlertButton)[];
    image?: string;
  }) {
    if (this.alert) {
      this.alert.dismiss();
      this.alert = null;
    }
    this.alert = await this.createYfAlert(opt);
    await this.alert.present();
  }

  async createYfAlert(opt: {
    header: string;
    message: string;
    buttons: (string | AlertButton)[];
    image?: string;
  }): Promise<HTMLIonAlertElement> {
    const message = opt.image
      ? new IonicSafeString(`<img src="assets/images/${opt.image}"/><ion-text>${opt.message}</ion-text>`)
      : `<ion-text>${opt.message}</ion-text>`;

    const alert = await this.alertCtrl.create({
      header: opt.header,
      message,
      cssClass: 'yf-alert',
      buttons: opt.buttons,
    });
    return alert;
  }

  async confirmAlert(title: string, body: string, confirmBtnColor: 'primary' | 'secondary' | 'tertiary' | 'danger' = 'primary', confirmText = "Conferma", cancelText = this.translate.instant("BUTTONS.CANCEL") ): Promise<boolean> {
    const alert = await this.alertCtrl.create({
      header: title,
      message: body,
      buttons: [
        { text: cancelText, role: 'cancel' },
        { text: confirmText, role: 'confirm', cssClass: `alert-button-${confirmBtnColor}` }
      ],
    })
    await alert.present();
    const rv = await alert.onDidDismiss();
    return rv.role === 'confirm';
  }

  async infoReservationParking(title: string, body: string, confirmBtnColor: 'primary' | 'secondary' | 'tertiary' | 'danger' = 'primary', confirmText = "Conferma", cancelText = this.translate.instant("BUTTONS.CANCEL")): Promise<boolean> {
    const alert = await this.alertCtrl.create({
      header: title,
      message: body,
      cssClass: 'yf-alert-landscape',
      backdropDismiss: true,
      buttons: ['Ok'],
    })
    await alert.present();
    const rv = await alert.onDidDismiss();
    return rv.role === 'confirm';
  }
}



// async presentAlertWithResponse(ao: AlertOptions): Promise<boolean> {
//   return new Promise<boolean>(async (resolve, reject) => {
//     if (this.alert) {
//       this.alert.dismiss();
//       this.alert = null;
//     }
//     const alert = await this.alertController.create({
//       header: ao.header ? ao.header : 'Default Header',
//       message: ao.message ? ao.message : 'Default Message',
//       cssClass: ao.cssClass ? ao.cssClass : '',
//       buttons: [
//         {
//           text: 'Annulla',
//           handler: (blah) => {
//             resolve(false);
//             // resolve({ role: 'ko', message: 'Annulla' });
//           },
//         },
//         {
//           text: 'OK',
//           handler: () => {
//             resolve(true);
//             // resolve({ role: 'ok', message: 'OK' });
//           },
//         },
//       ],
//     });
//     await alert.present();
//   });
// }

// async presentBasicAlert(ao: AlertOptions) {
//   if (this.alert) {
//     this.alert.dismiss();
//     this.alert = null;
//   }
//   this.alert = await this.alertController.create({
//     header: ao.header ? ao.header : 'Default Header',
//     message: ao.message ? ao.message : 'Default Message',
//     cssClass: ao.cssClass ? ao.cssClass : 'custom-alert',
//     buttons: ao.buttons ? ao.buttons : ['OK'],
//   });
//   this.alert.present();
// }
