// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-card {
  max-height: calc(100vh - 80px - 24px);
  overflow-y: auto;
  background: var(--ion-color-light);
  box-shadow: var(--yf-shadow);
}
@media (max-width: 576px) {
  ion-card {
    width: -webkit-fill-available;
    max-width: 360px;
  }
}
@media (min-width: 576px) {
  ion-card {
    width: 360px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/pages/filter/filter.page.scss","webpack://./src/theme/sass/abstracts/mixins.scss"],"names":[],"mappings":"AAEA;EACE,qCAAA;EACA,gBAAA;EACA,kCAAA;EACA,4BAAA;AADF;ACWI;EDdJ;IAOI,6BAAA;IACA,gBAAA;EAAF;AACF;ACKI;EDdJ;IAWI,YAAA;EAEF;AACF","sourcesContent":["@import './../../../theme/sass/abstracts/mixins.scss';\n\nion-card {\n  max-height: calc(100vh - 80px - 24px);\n  overflow-y: auto;\n  background: var(--ion-color-light);\n  box-shadow: var(--yf-shadow);\n  \n  @include breakpoint(sm, max) {\n    width: -webkit-fill-available;\n    max-width: 360px;\n  }\n  @include breakpoint(sm, min) {\n    width: 360px;\n  }\n}\n","$breakpoints: (\n  xxs: 375px,\n  xs: 450px,\n  sm: 576px,\n  md: 768px,\n  lg: 992px,\n  xl: 1200px,\n);\n\n// mobile-first breakpoint mixin\n@mixin breakpoint($breakpoint: md, $key: min, $orientation: false) {\n  @if ($orientation) {\n    @media (#{$key}-width: map-get($breakpoints, $breakpoint)) and (orientation : $orientation) {\n      @content;\n    }\n  } @else {\n    @media (#{$key}-width: map-get($breakpoints, $breakpoint)) {\n      @content;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
