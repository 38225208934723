import { Directive, HostListener, Input, OnInit, Output, EventEmitter, OnDestroy, ElementRef } from "@angular/core";
import { interval, Subject } from "rxjs";
import { takeUntil, tap, throttle } from "rxjs/operators";

@Directive({
  selector: '[debounceClick]',
})
export class DebounceClickDirective implements OnInit, OnDestroy {

  @Input() debounceTime = 500;
  @Input() debounceClass = 'button-disabled';
  @Output() debouncedClick = new EventEmitter();
  private destroy$ = new Subject<void>();
  private clicked = new Subject<Event>();

  constructor(private el: ElementRef) { }

  ngOnInit(): void {
    this.clicked.pipe(
      throttle(_ => interval(this.debounceTime).pipe(
        tap(_ => {
          this.el.nativeElement.classList.toggle(this.debounceClass)
        })
      )),
      tap(_ => this.el.nativeElement.classList.toggle(this.debounceClass)),
      takeUntil(this.destroy$),
    ).subscribe(e => {
      this.debouncedClick.emit(e);
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  @HostListener('click', ['$event'])
  clickEvent(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.clicked.next(event);
  }
}