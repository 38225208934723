// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-chip {
  --color: var(--ion-color-secondary);
  --background: var(--ion-color-tertiary);
}`, "",{"version":3,"sources":["webpack://./src/app/components/confirm-station-ops/confirm-station-ops.component.scss"],"names":[],"mappings":"AAEA;EACE,mCAAA;EACA,uCAAA;AADF","sourcesContent":["@import './../../../theme/sass/abstracts/mixins.scss';\n\nion-chip{\n  --color: var(--ion-color-secondary);\n  --background: var(--ion-color-tertiary);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
