import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-car-button',
  templateUrl: './car-button.component.html',
  styleUrls: ['./car-button.component.scss'],
})
export class CarButtonComponent implements OnInit {
  @Input() name: string;
  @Input() model: string;
  @Input() noShadow: boolean;
  @Input() isSelected: boolean;

  @Output() carButtonEvent = new EventEmitter();

  constructor() {}

  ngOnInit() {}
}
