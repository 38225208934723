// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.car-button {
  height: 50.4px;
  width: 100%;
  font-size: 16px;
  --padding-start: 16px;
  --padding-end: 16px;
}
.car-button ion-icon {
  min-height: 28px;
  min-width: 28px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/car-button/car-button.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,WAAA;EACA,eAAA;EACA,qBAAA;EACA,mBAAA;AACF;AAAE;EACE,gBAAA;EACA,eAAA;AAEJ","sourcesContent":[".car-button {\n  height: calc(56px * 0.9);\n  width: 100%;\n  font-size: 16px;\n  --padding-start: 16px;\n  --padding-end: 16px;\n  ion-icon {\n    min-height: 28px;\n    min-width: 28px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
