import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICreateReviewDTO, IReview } from 'common_library';

import { AppService } from './../app.service';

export const IMAGE_DIR = 'stored-image';

@Injectable({
  providedIn: 'root',
})
export class ReviewService {
  constructor(private http: HttpClient, private app: AppService) {}

  async create(rating: number, comment: string, stationId: string) {
    try {
      const dto: ICreateReviewDTO = {
        rating,
        comment,
        stationId,
      };

      await this.http.post<boolean>(`${this.app.apiUrl}/review`, dto).toPromise();
    } catch (error) {
      this.throwError(error);
    }
  }

  async getAllByStationId(stationId: string) {
    return await this.http
      .get<IReview[]>(`${this.app.apiUrl}/review?stationId=${stationId}`)
      .toPromise();
  }

  throwError(error: HttpErrorResponse) {
    let message: string;
    switch (error.status) {
      case 409:
        message = error.error.message;
        break;
      case 500:
        message = 'Errore Server';
        break;
      case 0:
        message = 'Impossibile raggiungere il server';
        break;
    }
    throw new Error(message);
  }

  async deleteReview(id: string) {
    try {
      await this.http.delete<IReview[]>(`${this.app.apiUrl}/review/${id}`).toPromise();
    } catch (error) {
      this.throwError(error);
    }
  }
}
