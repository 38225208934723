import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { IRfid, IUpdateRfidDTO } from 'common_library';
import { BehaviorSubject, Subscription } from 'rxjs';
import { CarService } from 'src/app/services/entities/car.service';
import { UserService } from 'src/app/services/entities/user.service';

@Component({
  selector: 'yf-rfid-form',
  templateUrl: './rfid-form.component.html',
  styleUrls: ['./rfid-form.component.scss'],
})
export class RfidFormComponent implements OnDestroy {
  @Input() userRfids: IRfid[] = []
  rfid: IRfid
  @Output() submitForm = new EventEmitter<IUpdateRfidDTO>();
  @Output() validityBtn = new EventEmitter<any>();
  @Output() cancelBtnEvent = new EventEmitter<any>();
  model$ = new BehaviorSubject<{ name: string, carId: number }>(null)

  sub: Subscription;

  form: UntypedFormGroup = new UntypedFormGroup({});
  options: FormlyFormOptions = {};

  fields: FormlyFieldConfig[] = this.getUpdatedFields();

  private langChangeSubscription: Subscription;

  constructor(
    private carService: CarService,
    private userService: UserService,
    private translate: TranslateService) {
      
    this.langChangeSubscription = this.translate.onLangChange.subscribe(() => {
      this.fields = this.getUpdatedFields();
    });

    this.sub = this.userService.selectedRfid$.subscribe((rfid) => {
      this.rfid = rfid
      this.model$.next(
        {
          name: rfid.name,
          carId: rfid.carId
        }
      )
       this.fields.forEach(element => {
        element.formControl?.markAsUntouched();
      });
    });

  }

  getUpdatedFields(): FormlyFieldConfig[] {
    return [
      {
        fieldGroup: [
          {
            key: "name",
            type: "f-input",
            templateOptions: {
              label: this.translate.instant("RFID.RFID_NAME"),
              type: 'text',
              required: true,
              maxLength: 12,
              placeholder: this.translate.instant("RFID.INSERT_NAME")
            },
            validators: {
              existingName: {
                expression: (c: AbstractControl) => {
                  return !(this.userRfids.some((a) => a.name === c.value && this.rfid.id !== a.id));
                },
                message: () => this.translate.instant("RFID.RFID_NAME_ALREADY_EXIST"),
              }
            }
          },
          {
            key: "carId",
            type: "f-select",
            props: {
              valueProp: 'id',
              labelProp: 'name',
              type: null,
              label: this.translate.instant("RFID.RFID_VEHICLE"),
              required: true,
              placeholder: this.translate.instant("RFID.SELECT_VEHICLE"),
              options: this.carService.$simpleUserCars
            },
          }
        ],
        fieldGroupClassName: "fg"
      }
    ];
  }





  ngOnDestroy(): void {
    this.sub.unsubscribe();
    this.langChangeSubscription.unsubscribe();
  }

  submit() {
    const rfid: IUpdateRfidDTO = {
      name: this.form.get('name').value as string,
      userId: this.userService.selectedRfid$.value.userId,
      valid: this.userService.selectedRfid$.value.valid,
      code: this.userService.selectedRfid$.value.code,
      carId: this.form.get('carId').value,
    }
    this.submitForm.emit(rfid);
  }
  cancelBtn() {
    this.userService.selectedRfid$.next(this.rfid);
  }
}
