import { NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { PowerPipe } from './power.pipe';
import { EnergyPipe } from './energy.pipe';
import { SameDatePipe } from './same-date.pipe';
import { PaymentPipe } from './payment.pipe';
import localeIt from '@angular/common/locales/it';
import { StationName } from './station-name.pipe';
import { HumanReadableTime } from './human-readable-time.pipe';

registerLocaleData(localeIt, 'it');

@NgModule({
  declarations: [
    PowerPipe,
    EnergyPipe,
    SameDatePipe,
    PaymentPipe,
    StationName,
    HumanReadableTime
  ],
  exports: [
    PowerPipe,
    EnergyPipe,
    SameDatePipe,
    PaymentPipe,
    StationName,
    HumanReadableTime
  ]
})

export class PipesModule {}
