import { Component, effect, signal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppNativeService } from 'src/app/services/app.native.service';
import { MainStateService } from 'src/app/services/state/app-main-state.service';
import { Browser } from '@capacitor/browser';
import { _ } from '../../consts';
import { PlatformType } from 'common_library';

@Component({
  selector: 'app-update-screen',
  templateUrl: './update-screen.component.html',
  styleUrls: ['./update-screen.component.scss']
})
export class UpdateScreenComponent {
  $show = signal<boolean>(false);
  $showAutomaticUpdateButton = signal<boolean>(true);

  constructor(
    private mainStateService: MainStateService,
    private appNativeService: AppNativeService,
    public translate: TranslateService
  ) {
    effect(async () => {
      let mustUpdate = false;
      let version = this.mainStateService.$version();
      let native = this.mainStateService.getNative();      
      if (native && version) {
        // in teoria la devo leggere dalla versione dello store, ma se per qualche motivo non ci riesco
        // prendo la versione scritta nell'ENV (che dovrebbe essere coerente)
        let appVersion = _.VERSION;
        try {
          appVersion = await this.appNativeService.getCurrentAppVersionName();
        } catch (ex) {
          this.$showAutomaticUpdateButton.set(false);
        }

        let majorRequiredVersion = parseInt(version.minSupportedFeVersion.split('.')[0]);
        let majorAppVersion = parseInt(appVersion.split('.')[0]);
        if (majorRequiredVersion > majorAppVersion) {
          mustUpdate = true;
        }
        // Le major sono compatibili: controllo le minor (assumendo che il valore della minor sia composto da 3 cifre)
        else {
          let minorRequiredVersion = parseInt(version.minSupportedFeVersion.split('.')[1]);
          let minorAppVersion = parseInt(appVersion.split('.')[1]);
          if (minorRequiredVersion > minorAppVersion) {
            mustUpdate = true;
          }
        }
      }

      this.$show.set(mustUpdate);
    }, { allowSignalWrites: true });
  }

  async updateApplication() {
    await this.appNativeService.startUpdate();
  }

  async openStoreLink() {
    let storeLink = '';
    if (this.mainStateService.getPlatformType() === PlatformType.AndroidNative) {
      storeLink = this.mainStateService.getVersion().playStoreLink;
    } else {
      storeLink = this.mainStateService.getVersion().appleStoreLink;
    }
    await Browser.open({ url: storeLink });
  }
}
