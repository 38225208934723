import { Component, EventEmitter, Output, computed, input } from '@angular/core';
import { ISession } from 'common_library';

@Component({
  selector: 'yf-session-toast-detail',
  templateUrl: './session-toast-detail.component.html',
  styleUrls: ['./session-toast-detail.component.scss'],
})
export class SessionToastDetailComponent {

  @Output() navigate: EventEmitter<any> = new EventEmitter<any>();
  @Output() onMap: EventEmitter<any> = new EventEmitter<any>();
  @Output() details: EventEmitter<any> = new EventEmitter<any>();

  $currentSession = input<ISession>(null);

  constructor() { }

  navigateTo(event: any) {
    this.navigate.emit(event);
  }

  showOnMap(event: any) {
    this.onMap.emit(event);
  }

  openDetails(event: any) {
    this.details.emit(event);
  }

  $stationIcon = computed(() => {
    if (this.$currentSession()?.connector?.station?.installation?.stations?.some((station) => station.cpo === 'YFL')) {
      return 'yfl';
    }
    else if (this.$currentSession()?.connector?.station?.installation?.stations?.some((station) => station.cpo === 'ENX')) {
      return 'enx';
    }
    else if (this.$currentSession()?.connector?.station?.installation?.stations?.some((station) => station.cpo === 'F2X')) {
      return 'ftx';
    }
    return 'not_yfl';
  });
}
