import { Injectable } from '@angular/core';
import { PushNotifications, Token, PushNotificationSchema, ActionPerformed } from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';
import { HttpIoService } from '../communication/http-io.service';
import { IClient } from 'common_library';
import { distinctUntilChanged, map } from 'rxjs';
import { FCM } from "@capacitor-community/fcm";
import { MainStateService } from '../state/app-main-state.service';

const CONTROLLER_ROOT = 'client';

@Injectable({ providedIn: 'root' })
export class PushNotificationsNativeService {

  private token: string;
  userId: string;
  readonly clientId: string;

  constructor(    
    private HIO: HttpIoService,    
    private mainStateService: MainStateService
  ) {
    this.clientId = this.mainStateService.getClientId();
    const userId$ = this.mainStateService.user$.pipe(map(u => u?.id), distinctUntilChanged())

    userId$.subscribe((userId) => {
      if (!this.isPushAvailable()) return;
      if (!!userId) {
        console.log('🚀 : PushhNotificationService : user', userId);
        this.init();
      } else {
        this.clean();
      }
    });
  }

  getToken() {
    return this.token;
  }

  setToken(token: string) {
    this.token = token;
  }

  async init() {
    if (!this.isPushAvailable())
      return;

    await PushNotifications.addListener('registration', async (token: Token) => {
      this.setToken(token.value);
      await this.updateUserRegisterationToken(token.value);
    });

    // Some issue with your registration and the notifications will not work
    await PushNotifications.addListener('registrationError', (error: any) => {
      console.log('Error on registration: ' + JSON.stringify(error));
    });

    // Show the notification if the app is open on your device
    await PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
      console.log('Push received: ' + JSON.stringify(notification));
    });

    // Method called when tapping on a notification on your device
    await PushNotifications.addListener('pushNotificationActionPerformed', (notification: ActionPerformed) => {
      console.log('Push action performed: ' + JSON.stringify(notification));
    });

    await this.requestPermissions();
  }

  async requestPermissions() {
    if (!this.isPushAvailable())
      return;

    if (Capacitor.getPlatform() !== 'web') {
      const permissionStatus = await PushNotifications.requestPermissions();

      if (permissionStatus.receive === 'granted') {
        await PushNotifications.register();
      } else {
        //TODO: Some function with the user who does not give permission
      }
    }
  };

  async resetPushNotifications() {
    await PushNotifications.removeAllDeliveredNotifications();
  };

  public async clean() {
    if (this.clientId) {
      PushNotifications.removeAllDeliveredNotifications();
      await FCM.deleteInstance();
    }
  }

  private isPushAvailable() {
    return Capacitor.isPluginAvailable('PushNotifications');
  }

  private isFCMAvailable() {
    return Capacitor.isPluginAvailable('FCM');
  }

  async updateUserRegisterationToken(token: string): Promise<void> {
    try {
      const platformType = this.mainStateService.getPlatformType();
      const dto: Partial<IClient> = {
        wpEndpoint: {
          fcmToken: token,
          platformType
        }
      }
      const rv = await this.HIO.patchItem<IClient, Partial<IClient>>('client', this.clientId, dto)
    } catch (error) {
      console.log(error)
    }
  }
}
