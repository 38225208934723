// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-toolbar {
  --background: none;
  padding-top: 10px;
}

.yf-container {
  overflow-y: auto;
  padding-bottom: 18px;
}
@media (max-width: 768px) {
  .yf-container {
    max-height: calc(100vh - 220px);
  }
}
@media (min-width: 768px) {
  .yf-container {
    max-height: calc(100vh - 250px);
  }
}
@media (min-width: 992px) {
  .yf-container {
    max-height: calc(100vh - 370px);
  }
}
.yf-container ion-card {
  border-radius: 0px !important;
  padding: 16px;
  border: 1px solid rgba(162, 165, 173, 0.4);
  border-left: 8px solid var(--ion-color-primary);
  margin: 8px;
}
.yf-container ion-card.missing-data {
  border-left: 8px solid var(--ion-color-danger) !important;
}
.yf-container ion-card-header {
  padding-left: 0px;
  padding-top: 0px;
  padding-bottom: 12px;
}
.yf-container ion-card-content {
  width: 100%;
  padding: 0px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/rfid-form/rfid-form.component.scss"],"names":[],"mappings":"AACA;EACE,kBAAA;EACA,iBAAA;AAAF;;AAGA;EACE,gBAAA;EACA,oBAAA;AAAF;AAEE;EAJF;IAKI,+BAAA;EACF;AACF;AACE;EARF;IASI,+BAAA;EAEF;AACF;AAAE;EAZF;IAaI,+BAAA;EAGF;AACF;AADE;EACE,6BAAA;EACA,aAAA;EACA,0CAAA;EACA,+CAAA;EACA,WAAA;AAGJ;AAFI;EACE,yDAAA;AAIN;AAAE;EACC,iBAAA;EACA,gBAAA;EACA,oBAAA;AAEH;AACE;EACE,WAAA;EACA,YAAA;AACJ","sourcesContent":["\nion-toolbar{\n  --background: none;\n  padding-top: 10px;\n}\n\n.yf-container{\n  overflow-y: auto;\n  padding-bottom: 18px;\n  \n  @media (max-width: 768px) {\n    max-height: calc(100vh - 220px);\n  }\n  \n  @media (min-width: 768px) {\n    max-height: calc(100vh - 250px);\n  }\n\n  @media (min-width: 992px) {\n    max-height: calc(100vh - 370px);\n  }\n  \n  ion-card {\n    border-radius: 0px!important;\n    padding: 16px;\n    border: 1px solid rgba(162, 165, 173, 0.4); \n    border-left: 8px solid var(--ion-color-primary);\n    margin: 8px;\n    &.missing-data{\n      border-left: 8px solid var(--ion-color-danger)!important;\n    }\n  }\n\n  ion-card-header{\n   padding-left: 0px;\n   padding-top: 0px;\n   padding-bottom: 12px;\n  }\n\n  ion-card-content {\n    width: 100%;\n    padding: 0px;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
