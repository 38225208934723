import { Component, OnInit, NgZone } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IRfid, IStation, IUpdateRfidDTO, IUpdateStationDTO, PlatformType } from 'common_library';
import { AuthService } from 'src/app/services/auth/auth.service';
import { HttpIoService } from 'src/app/services/communication/http-io.service';
import { MasterDetailShowType } from '../master-detail/master-detail.component';
import { UserService } from 'src/app/services/entities/user.service';
import { ToastService } from 'src/app/services/utils/toast.service';
import { ActionSheetService, ACTION_SHEET_ROLES } from 'src/app/services/utils/actionsheet.service';
import { Keyboard } from '@capacitor/keyboard';
import { TranslateService } from '@ngx-translate/core';
import { MainStateService } from 'src/app/services/state/app-main-state.service';

const CONTROLLER_ROOT = 'rfid/user-request';

@Component({
  selector: 'yf-rfid-details',
  templateUrl: './rfid-details.component.html',
  styleUrls: ['./rfid-details.component.scss'],
})
export class RfidDetailsComponent implements OnInit {

  result: string;
  station: IStation;
  masterDetailShow: MasterDetailShowType = 'master';
  compactMode: boolean;
  currentStep = 0;
  rfids: IRfid[] = [];
  footerVisible: boolean = true;
  pageTitle: string;
  detailTitle: string;
  pageSubtitle: string;
  masterTitle: string;

  execStep(id: number) {
    this.currentStep = id;
    this.masterDetailShow = 'detail';
  }

  constructor(
    private modalCtrl: ModalController,
    private HIO: HttpIoService,
    private mainStateService: MainStateService,
    public userService: UserService,
    private toast: ToastService,
    private actionSheetService: ActionSheetService,    
    private ngZone: NgZone,
    private translate: TranslateService
  ) {
    if (this.mainStateService.getNative()) {
      this.listenKeyboardVisibility();
    }
  }

  async ngOnInit() {
    await this.userService.getRfids();
    this.userService.rfid$.subscribe(res => {
      this.rfids = res;
    })
    this.selectFirstIfNeeds();
  }

  selectFirstIfNeeds() {
    if (!this.compactMode && this.rfids.length > 0 && !this.userService.selectedRfid$.value) this.pickAnRfid(this.rfids[0]);
  }

  pickAnRfid(rfid: IRfid) {
    this.userService.selectedRfid$.next(rfid);
    // this.selectedRfid = rfid;
    this.masterDetailShow = 'detail';
  }

  compactModeChange(compactMode: boolean) {
    this.compactMode = compactMode;
  }

  async toggleValidity(rfid: IRfid) {
    const userChoice = await this.actionSheetService.actionSheetFunc(this.translate.instant("RFID.CONFIRM_UPDATE"), `${rfid.name} ${this.translate.instant("RFID.WILL_BE")} ${this.validationType(rfid.valid)}`);
    if (userChoice === ACTION_SHEET_ROLES.confirm) {
      try {
        const rv = await this.HIO.post<IRfid, IUpdateRfidDTO>('rfid/edit', { ...rfid, valid: !rfid.valid });
        await this.toast.presentBasicToast({ message: `${this.translate.instant("RFID.RFID_TAG")} ${this.validationType(rfid.valid)}`, color: 'success', icon: 'id-card-outline' });
        this.userService.selectedRfid$.next(rv)
      } catch (err) {
        await this.toast.presentBasicToast({ message: this.translate.instant("RFID.CANT_UPDATE"), color: 'danger', icon: 'warning' });

      }
    }
  }

  async deleteRfid(rfid: IRfid) {
    const userChoice = await this.actionSheetService.actionSheetFunc(this.translate.instant("RFID.CONFIRM_DELETION"), `${rfid.name} ${this.translate.instant("RFID.WILL_BE_DELETED")}`)
    if (userChoice === ACTION_SHEET_ROLES.confirm) {
      try {
        await this.HIO.deleteItem('rfid/delete', rfid.id);
        this.masterDetailShow = 'master'
        await this.toast.presentBasicToast({ message: this.translate.instant("RFID.DELETED_MESSAGE"), color: 'success', icon: 'id-card-outline' });
      } catch (err) {
        await this.toast.presentBasicToast({ message: this.translate.instant("RFID.CANT_DELETE"), color: 'danger', icon: 'warning' });
      }
    }
  }

  isSelected(code) {
    if (this.userService?.selectedRfid$?.value?.code) {
      if (code === this.userService?.selectedRfid$.value.code) {
        return true;
      } else {
        return false;
      }
    }
  }

  closeMe() {
    this.modalCtrl.dismiss();
  }

  backBtn() {
    if (this.compactMode) {
      this.masterDetailShow = 'master'
    } else {
      this.closeMe()
    }
  }

  async addRfid() {
    if (this.station?.id) {
      const rv = await this.postRfidRequest();
      if (rv) {
        await this.toast.presentBasicToast({ message: this.translate.instant("RFID.PASS_RFID_ON_STATION_READER"), color: 'success', icon: 'id-card-outline' });
      } else {
        await this.toast.presentBasicToast({ message: `${this.translate.instant("RFID.CANT_CONNECT")}, ${this.translate.instant("TOAST_MESSAGES.TRY_AGAIN_LATER")}`, color: 'primary', icon: 'id-card-outline' });
      }
    } else {
      await this.toast.presentBasicToast({ message: `${this.translate.instant("TOAST_MESSAGES.SELECT_A_STATION")}`, color: 'warning', icon: 'id-card-outline' });
    }
  }

  async submitForm(rfidForm: IUpdateRfidDTO) {
    try {
      await this.HIO.post<IRfid, IUpdateRfidDTO>('rfid/edit', rfidForm)
      await this.toast.presentBasicToast({ message: this.translate.instant("RFID.UPDATED_SUCCESSFULLY"), color: 'success', icon: 'id-card-outline' });
    } catch (err) {
      await this.toast.presentBasicToast({ message: this.translate.instant("RFID.CANT_UPDATE"), color: 'danger', icon: 'warning' });
    }
  }

  private async postRfidRequest() {
    let date = new Date();
    date.setSeconds(date.getSeconds() + 60);

    const dto: IUpdateStationDTO = {
      rfidTimeOut: date,
      rfidUserId: this.mainStateService.getUser().id,
    }
    return await this.HIO.patchItem<IStation, IUpdateStationDTO>(`station/station-rfid`, this.station.id, dto);
  }

  validationType(bool: boolean) {
    if (bool) {
      return this.translate.instant("RFID.DISABLED_RFID");
    } else {
      return this.translate.instant("RFID.ENABLED_RFID");
    }
  }

  listenKeyboardVisibility() {
    Keyboard.addListener('keyboardWillShow', () => {
      this.ngZone.run(() => {
        this.footerVisible = false;
      });
    });

    Keyboard.addListener('keyboardDidHide', () => {
      this.ngZone.run(() => {
        this.footerVisible = true;
      });
    });
  }
}