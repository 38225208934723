// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  .divider {
    margin: 8px;
    border-top-width: 2px;
    border-top-style: solid;
    border-color: #d5d5d5;
  }
  `, "",{"version":3,"sources":["webpack://./src/app/components/divider/divider.component.ts"],"names":[],"mappings":";EACE;IACE,WAAW;IACX,qBAAqB;IACrB,uBAAuB;IACvB,qBAAqB;EACvB","sourcesContent":["\n  .divider {\n    margin: 8px;\n    border-top-width: 2px;\n    border-top-style: solid;\n    border-color: #d5d5d5;\n  }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
