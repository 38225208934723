// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-update-screen {
  background: var(--ion-color-secondary);
  position: fixed;
  display: flex;
  z-index: 100;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: calc(env(safe-area-inset-top) + 32px);
  padding-bottom: calc(env(safe-area-inset-bottom) + 32px);
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  width: 100%;
  height: 100%;
}

.white-text {
  color: white;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/app/components/update-screen/update-screen.component.scss"],"names":[],"mappings":"AAAA;EACE,sCAAA;EACA,eAAA;EACA,aAAA;EACA,YAAA;EACA,kBAAA;EACA,mBAAA;EACA,kDAAA;EACA,wDAAA;EACA,sBAAA;EACA,8BAAA;EACA,mBAAA;EACA,WAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;AACF;;AAEA;EACE,YAAA;EACA,kBAAA;AACF","sourcesContent":[".app-update-screen {\n  background: var(--ion-color-secondary);\n  position: fixed;\n  display: flex;\n  z-index: 100;\n  padding-left: 16px;\n  padding-right: 16px;\n  padding-top: calc(env(safe-area-inset-top) + 32px);\n  padding-bottom: calc(env(safe-area-inset-bottom) + 32px);\n  flex-direction: column;\n  justify-content: space-between;\n  align-items: center;\n  flex: 1 0 0;\n  align-self: stretch;\n  width: 100%;\n  height: 100%;\n}\n\n.white-text {\n  color: white;\n  text-align: center;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
