import { Injectable } from '@angular/core';
import {
  ClientEvents,
  IInstallation,
  WsNamespaces,
} from 'common_library';
import { BehaviorSubject } from 'rxjs';
import { SocketIoService } from '../communication/socket-io.service';
import { InstallationProperties } from 'src/app/components/map/map.component';
import { MainStateService } from '../state/app-main-state.service';

const CONTROLLER_ROOT = 'installation';

@Injectable({ providedIn: 'root' })
export class SelectedClusterService {

  selectedCluster$ = new BehaviorSubject<IInstallation[]>(null)
  idSet: Set<string> = new Set();
  installationToUpdateIds: string[] = [];
  installationToUpdate: IInstallation[] = [];

  constructor(
    private mainStateService: MainStateService,
    private SIS: SocketIoService) {
  }

  async selectCluster(ids: string[]) {
    try {
      const rv = await this.getInstallationList(ids);
      this.mainStateService.setSelectedCluster(rv);
      return rv;
    } catch (error) {
      console.log("Error: 🐸 -> ", error)
    }
  }

  private async getInstallationList(ids: string[]): Promise<IInstallation[]> {
    return await this.SIS.sendRequest<string[], IInstallation[]>(WsNamespaces.Client, ClientEvents.GetClusterByIds, ids);
  }

  async updateCluster(iu: InstallationProperties[]) {
    this.idSet = new Set<string>();
    this.findInstallationInCluster(iu);
    if (this.installationToUpdateIds.length > 0) {
      this.installationToUpdate = await this.getInstallationList(this.installationToUpdateIds);
    }

    if (this.selectedCluster$?.getValue()) {
      this.selectedCluster$.getValue().forEach(installazione => {
        this.idSet.add(installazione.id);
      });
    }

    this.installationToUpdateIds.forEach(installazione => {
      this.idSet.add(installazione);
    });

    this.selectNewCluster();
  }

  findInstallationInCluster(iu: InstallationProperties[]) {
    this.installationToUpdateIds = [];
    for (let i = 0; i < iu.length; i++) {
      const el = iu[i];
      if (this.selectedCluster$.getValue()?.find(installation => installation?.id === el.id)) {
        this.installationToUpdateIds.push(el.id);
      }
    }
  }

  selectNewCluster() {
    const installationToSelect = Array.from(this.idSet).map(id => {
      const installazioneSelezionata = this.selectedCluster$.getValue()?.find(installazione => installazione.id === id);

      const installazioneAggiornata = this.installationToUpdate.find(installazione => installazione.id === id);
      if (installazioneAggiornata) {
        return installazioneAggiornata;
      } else {
        return installazioneSelezionata;
      }
    });
    if (installationToSelect && installationToSelect.length > 0) {
      this.selectedCluster$.next(installationToSelect);
    }
    else {
      this.selectedCluster$.next(null);
    }

  }
}