// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-container {
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--yf-white);
  color: black;
}
.text-container.isMine {
  background-color: var(--ion-color-secondary);
  color: white;
}
.text-container ion-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

ion-card {
  width: 100px;
  height: 52px;
  padding: 0px;
  margin: 0px !important;
  cursor: pointer;
}

ion-card-content {
  width: 100%;
  padding: 0;
}

yf-station-info {
  display: block;
  margin-top: 36px;
  margin-bottom: 24px;
}

ion-button {
  height: 48px;
  margin: 5px;
  border-radius: var(--yf-border-radius);
  --padding-end: 0;
  --padding-start: 0;
  --padding-bottom: 10px;
}

.selected-tab-dark {
  background-color: var(--ion-color-secondary);
  border-top-left-radius: var(--yf-spacing1);
  border-top-right-radius: var(--yf-spacing1);
}

.selected-tab-light {
  background-color: #fff;
  border-top-left-radius: var(--yf-spacing1);
  border-top-right-radius: var(--yf-spacing1);
}

.btn {
  --box-shadow: none;
}

.border {
  border: 1px solid lightgray;
}`, "",{"version":3,"sources":["webpack://./src/app/components/mini-station/mini-station.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,iCAAA;EACA,YAAA;AACF;AAAE;EACE,4CAAA;EACA,YAAA;AAEJ;AAAE;EACE,gBAAA;EACA,uBAAA;EACA,mBAAA;AAEJ;;AAEA;EACE,YAAA;EACA,YAAA;EACA,YAAA;EACA,sBAAA;EAEA,eAAA;AAAF;;AAGA;EACE,WAAA;EACA,UAAA;AAAF;;AAGA;EACE,cAAA;EACA,gBAAA;EACA,mBAAA;AAAF;;AAGA;EACE,YAAA;EACA,WAAA;EACA,sCAAA;EACA,gBAAA;EACA,kBAAA;EACA,sBAAA;AAAF;;AAEA;EACE,4CAAA;EACA,0CAAA;EACA,2CAAA;AACF;;AACA;EACE,sBAAA;EACA,0CAAA;EACA,2CAAA;AAEF;;AAAA;EACE,kBAAA;AAGF;;AAAA;EACE,2BAAA;AAGF","sourcesContent":[".text-container {\n  height: 28px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background-color: var(--yf-white);\n  color: black;\n  &.isMine {\n    background-color: var(--ion-color-secondary);\n    color: white;\n  }\n  ion-text {\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n  }\n}\n\nion-card {\n  width: 100px;\n  height: 52px;\n  padding: 0px;\n  margin: 0px !important;\n  // box-shadow: var(--yf-shadow);\n  cursor: pointer;\n}\n\nion-card-content {\n  width: 100%;\n  padding: 0;\n}\n\nyf-station-info {\n  display: block;\n  margin-top: 36px;\n  margin-bottom: 24px;\n}\n\nion-button {\n  height: 48px;\n  margin: 5px;\n  border-radius: var(--yf-border-radius);\n  --padding-end: 0;\n  --padding-start: 0;\n  --padding-bottom: 10px;\n}\n.selected-tab-dark{\n  background-color: var(--ion-color-secondary);\n  border-top-left-radius: var(--yf-spacing1);\n  border-top-right-radius: var(--yf-spacing1);\n}\n.selected-tab-light{\n  background-color: #fff;\n  border-top-left-radius: var(--yf-spacing1);\n  border-top-right-radius: var(--yf-spacing1);\n}\n.btn{\n  --box-shadow: none;\n}\n\n.border {\n  border: 1px solid lightgray;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
