import { Injectable } from '@angular/core';
import { IConnector, IInstallation, ISession, ISessionResult, IStation, SessionCmd, IUser, ErrorCodeValues, ErrorCode } from 'common_library';
import { AppService } from './app.service';
import { SessionService } from './entities/session.service';
import { AuthType } from 'common_library';
import { ToastService } from './utils/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { Point } from 'geojson';
import { MainStateService } from './state/app-main-state.service';

export interface StateUI {
  station: IStation;
  connector?: IConnector;
  session?: ISession;
  isMine: boolean;
  notAvailable: boolean;
}
export interface IGeneralState {
  user?: {
    currentUser: IUser,
    session?: ISession,
  },
  selectedInstallation: IInstallation,
  selectedStation: IStation,
  selectedConnetor: IConnector,
  ui: {
    textColor: string,
    stateBackground: string,
    stateLabel: string,
  }
}
@Injectable({ providedIn: 'root' })
export class CoreService {

  constructor(
    private SeS: SessionService,
    public APP: AppService,
    public mainStateService: MainStateService,
    private toastService: ToastService,
    private translate: TranslateService
  ) { }

  async reserveConnector(connectorId: number, coord?: Point): Promise<ISessionResult> {
    return await this.SeS.requestCommand({
      cmd: SessionCmd.Reserve,
      connectorId,
      carId: this.mainStateService.getSelectedUserCarForSession()?.id,
      authType: AuthType.APP_USER,
      coord,
      clientId: this.mainStateService.getClientId()
    });
  }

  async cancelReservation(connectorId: number, sessionId: string): Promise<ISessionResult> {
    const rv = await this.SeS.requestCommand({
      cmd: SessionCmd.CancelReservation,
      connectorId,
      sessionId
    });
    return rv;
  }

  async startCharge(connectorId: number, sessionId: string, coord?: Point): Promise<ISessionResult> {
    const rv = await this.SeS.requestCommand({
      cmd: SessionCmd.StartCharge,
      connectorId,
      carId: this.mainStateService.getSelectedUserCarForSession()?.id,
      sessionId,
      authType: AuthType.APP_USER,
      coord,
      clientId: this.mainStateService.getClientId()
    });
    return rv;
  }

  async stopCharge(connectorId: number, sessionId: string): Promise<ISessionResult> {
    const rv = await this.SeS.requestCommand({
      cmd: SessionCmd.StopCharge,
      connectorId,
      sessionId
    });
    return rv;
  }

  async endSession(connectorId: number, sessionId: string): Promise<ISessionResult> {
    const rv = await this.SeS.requestCommand({
      cmd: SessionCmd.EndSession,
      connectorId,
      sessionId,
    });
    return rv;

  }

  async manageErrorCodes(errorCode: ErrorCodeValues) {
    if (errorCode === ErrorCode.CdcNotValid) {
      await this.toastService.presentBasicToast({ message: this.translate.instant("CDC_AUTHENTICATION.PAYMENT_FAILED"), color: 'danger', icon: 'warning' });
    }
    else if (errorCode === ErrorCode.UnpaidSession) {
      await this.toastService.presentUnpaidSessionToast();
    }
    else if (errorCode === ErrorCode.OcpiSessionActive) {
      await this.toastService.presentBasicToast({ message: this.translate.instant("TOAST_MESSAGES.OCPI_SESSION_ACTIVE"), color: 'warning', icon: 'warning' });
    }
    else {
      let key = Object.keys(ErrorCode).find(key => ErrorCode[key] === errorCode);
      await this.toastService.presentBasicToast({ message: this.translate.instant("TOAST_MESSAGES.ERROR_WITH_CODE", { key }), color: 'danger', icon: 'warning' });
    }
  }
}
