import { Injectable } from '@angular/core';
import { ActionSheetController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

export enum ACTION_SHEET_ROLES {
  confirm = '1',
  cancel = '0'
}


@Injectable({
  providedIn: 'root'
})
export class ActionSheetService {

  constructor(
    private actionSheetCtrl: ActionSheetController,
    private translate: TranslateService) {
      
    }

  async actionSheetFunc(header: string, subHeader: string){
    const actionSheet = await this.actionSheetCtrl.create({
      header: subHeader,
      // subHeader: subHeader,
      buttons: [
        {
          text: this.translate.instant("BUTTONS.CONFIRM"),
          role: ACTION_SHEET_ROLES.confirm,
          cssClass: '',
          data: {
            action: 'confirm',
          },
        },
        {
          text: this.translate.instant("BUTTONS.CANCEL"),
          role: ACTION_SHEET_ROLES.cancel,
          cssClass: '',
          data: {
            action: 'cancel',
          },
        },
      ],
    });

    await actionSheet.present();

    const result = await actionSheet.onDidDismiss();
    return result.role
  }




}
