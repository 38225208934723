import { Component, OnInit } from '@angular/core';
import { InfiniteScrollCustomEvent, ModalController } from '@ionic/angular';
import { CU, INotification, NotificationType } from 'common_library';
import { MasterDetailShowType } from '../master-detail/master-detail.component';
import { UserService } from 'src/app/services/entities/user.service';
import { NotificationService } from 'src/app/services/entities/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { MainStateService } from 'src/app/services/state/app-main-state.service';

const CONTROLLER_ROOT = 'notification/user-notification';

@Component({
  selector: 'yf-notification-details',
  templateUrl: './notification-details.component.html',
  styleUrls: ['./notification-details.component.scss'],
})
export class NotificationDetailsComponent implements OnInit {
  NotificationTypes = NotificationType
  _utilityFuncs = CU;
  notificationId;
  masterDetailShow: MasterDetailShowType = 'master';
  compactMode: boolean;
  currentStep = 0;
  notifications: INotification[] = [];
  selectedNotification: INotification;
  totalNotificationsNumber = 0;
  currentPage = 0;
  pageSize = 50;
  loading = false;
  pageTitle: string;
  pageSubtitle: string;
  pageDetailTitle: string;

  execStep(id: number) {
    this.currentStep = id;
    this.masterDetailShow = 'detail';
  }

  constructor(
    private modalCtrl: ModalController,
    public userService: UserService,
    public mainStateService: MainStateService,
    public notificationService: NotificationService,
    private translate: TranslateService
  ) { }

  async ngOnInit() {
    await this.refreshData();
    this.selectFirstIfNeeds();
  }

  async refreshData() {
    this.loading = true;
    this.currentPage = 0;
    const rv = await this.notificationService.fetchNotificationsPaged(this.currentPage, this.pageSize);
    this.notifications = rv.data;
    this.totalNotificationsNumber = rv.count;
    this.loading = false;
  }

  async handleRefresh(event) {
    await this.refreshData();
    // Any calls to load data go here
    event.target.complete();
  }

  selectFirstIfNeeds() {
    if (!this.compactMode && !this.selectedNotification) this.pickAnNotification(this.notifications[0]);
  }

  pickAnNotification(notification: INotification) {
    this.notificationService.selectedNotification$.next(notification);
    this.selectedNotification = notification;
    this.masterDetailShow = 'detail';
    this.notificationRead();
  }

  async notificationRead() {
    if (!!this.selectedNotification && !this.selectedNotification.readedAt) {
      const rv = await this.notificationService.updateNotification(this.selectedNotification.id, { clientId: this.mainStateService.getClientId() })
      const index = this.notifications.findIndex((c) => c.id === this.selectedNotification.id)
      this.notifications[index] = rv
    }
  }

  compactModeChange(compactMode: boolean) {
    this.compactMode = compactMode;
  }

  accepted(i: number) {

  }

  denied(i: number) {

  }

  closeMe() {
    this.modalCtrl.dismiss();
  }

  timeSince(date: Date = new Date()) {
    return this._utilityFuncs.timeSince(date);
  }

  async onIonInfinite(evt) {
    if (this.notifications.length < this.totalNotificationsNumber) {
      this.loading = true;
      this.currentPage = this.currentPage + 1;
      const rv = await this.notificationService.fetchNotificationsPaged(this.currentPage, this.pageSize);
      this.notifications.push(...rv.data);
      this.loading = false;
    }
    (evt as InfiniteScrollCustomEvent).target.complete();
  }
}
