// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.master {
  min-width: 360px;
}

.yf-master-detail .master {
  width: 100% !important;
}

.yf-master-detail .detail {
  width: 100% !important;
}

.oy-a {
  overflow-y: auto;
}

.master-item {
  font-size: medium;
  color: var(--ion-color-secondary);
  border: 1px solid rgb(99, 99, 99);
  padding: 10px;
}

.detail {
  height: 100%;
  min-width: 360px;
  overflow-y: auto;
}

.detail-item {
  font-size: medium;
  color: var(--ion-color-secondary);
  border-left: 1px solid #eee;
  padding: 10px;
  height: 100%;
}

.detail-item-no-border {
  font-size: medium;
  color: var(--ion-color-secondary);
  padding: 10px;
  height: 100%;
}

.subtitle {
  border-radius: 8px;
}

.selected {
  background: #f2f2f9;
}

.item-icon {
  margin-inline-end: 10px !important;
}

ion-card {
  padding-top: 0px;
}

ion-item-divider {
  padding-left: 0px;
}

ion-toolbar {
  --background: none;
}

.section-title {
  font-weight: bold;
  font-size: large;
}

.section-subtitle {
  font-size: smaller;
  color: #666;
}

.section-img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.section-html {
  padding-bottom: 48px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/master-details-app-info/app-info.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AAEA;EACE,sBAAA;AACF;;AAEA;EACE,sBAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,iBAAA;EACA,iCAAA;EACA,iCAAA;EACA,aAAA;AACF;;AAEA;EACE,YAAA;EACA,gBAAA;EACA,gBAAA;AACF;;AAEA;EACE,iBAAA;EACA,iCAAA;EACA,2BAAA;EACA,aAAA;EACA,YAAA;AACF;;AAEA;EACE,iBAAA;EACA,iCAAA;EACA,aAAA;EACA,YAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,kCAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,iBAAA;EACA,gBAAA;AACF;;AAEA;EACE,kBAAA;EACA,WAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;AACF;;AAEA;EACE,oBAAA;AACF","sourcesContent":[".master {\n  min-width: 360px;\n}\n\n.yf-master-detail .master {\n  width: 100% !important;\n}\n\n.yf-master-detail .detail {\n  width: 100% !important;\n}\n\n.oy-a {\n  overflow-y: auto;\n}\n\n.master-item {\n  font-size: medium;\n  color: var(--ion-color-secondary);\n  border: 1px solid rgb(99, 99, 99);\n  padding: 10px;\n}\n\n.detail {\n  height: 100%;\n  min-width: 360px;\n  overflow-y: auto;\n}\n\n.detail-item {\n  font-size: medium;\n  color: var(--ion-color-secondary);\n  border-left: 1px solid #eee;\n  padding: 10px;\n  height: 100%;\n}\n\n.detail-item-no-border {\n  font-size: medium;\n  color: var(--ion-color-secondary);\n  padding: 10px;\n  height: 100%;\n}\n\n.subtitle {\n  border-radius: 8px;\n}\n\n.selected {\n  background: #f2f2f9;\n}\n\n.item-icon {\n  margin-inline-end: 10px !important;\n}\n\nion-card {\n  padding-top: 0px;\n}\n\nion-item-divider {\n  padding-left: 0px;\n}\n\nion-toolbar {\n  --background: none;\n}\n\n.section-title {\n  font-weight: bold;\n  font-size: large;\n}\n\n.section-subtitle {\n  font-size: smaller;\n  color: #666;\n}\n\n.section-img {\n  width: 100%;\n  height: auto;\n  border-radius: 8px;\n}\n\n.section-html {\n  padding-bottom: 48px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
