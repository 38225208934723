import { Injectable } from '@angular/core';
import { Position } from 'geojson';

@Injectable({
  providedIn: 'root',
})
export class NavigateToPointService {
  constructor() {}

  navigateTo(coords: Position) {
    const link = document.createElement('a');
    link.target = '_blank';
    link.href = `https://www.google.com/maps/search/?api=1&query=${coords[1]}%2C${coords[0]}`;
    link.click();
    link.remove();
  }
}

