import { Component, Input } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { CoreService } from 'src/app/services/core.service';
import { MainStateService } from 'src/app/services/state/app-main-state.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-debug-full',
  templateUrl: './debug-full.component.html',
  styleUrls: ['./debug-full.component.scss'],
})
export class DebugFullComponent {

  @Input() data: any;
  hide: boolean = !!environment.production;

  constructor(public mainStateService: MainStateService, public app: AppService) { }

  get _data(): any {
    return this.data || { connector: this.mainStateService.$selectedConnector(), station: this.mainStateService.$selectedStation(), installation: this.mainStateService.$selectedInstallation() };
  }
}