// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.formly-input-container {
  padding-bottom: 8px;
  margin-bottom: 8px;
  position: relative;
}

ion-item {
  align-items: flex-end;
  --highlight-height: 0px;
}
ion-item ion-label {
  transform: translateY(100%);
  --color: var(--ion-color-medium);
}

.input-error {
  position: absolute;
  font-size: 0.7rem;
  padding: 1px 0px 0px;
  z-index: 1002;
  width: 100%;
}

.show-password {
  font-size: 1.2rem;
  color: var(--ion-color-dark);
  opacity: 60%;
  padding-bottom: 4px;
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/my-formly/components/formly.global.scss"],"names":[],"mappings":"AACA;EACE,mBAAA;EACA,kBAAA;EACA,kBAAA;AAAF;;AAGA;EACE,qBAAA;EAEA,uBAAA;AADF;AAEE;EACE,2BAAA;EAEA,gCAAA;AADJ;;AAKA;EACE,kBAAA;EACA,iBAAA;EACA,oBAAA;EAEA,aAAA;EACA,WAAA;AAHF;;AAMA;EACE,iBAAA;EACA,4BAAA;EACA,YAAA;EAEA,mBAAA;EACA,SAAA;AAJF","sourcesContent":["\n.formly-input-container {\n  padding-bottom: 8px;\n  margin-bottom: 8px;\n  position: relative;\n}\n\nion-item {\n  align-items: flex-end;\n  // --border-width: 0 0 1px 0;\n  --highlight-height: 0px;\n  ion-label {\n    transform: translateY(100%);\n    // transform: translateY(calc(100% + 8px));\n    --color: var(--ion-color-medium);\n  }\n}\n\n.input-error {\n  position: absolute;\n  font-size: 0.7rem;\n  padding: 1px 0px 0px;\n\n  z-index: 1002;\n  width: 100%;\n}\n\n.show-password {\n  font-size: 1.2rem;\n  color: var(--ion-color-dark);\n  opacity: 60%;\n  // height: 44px;\n  padding-bottom: 4px;\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
