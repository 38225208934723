// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  url:"https://yf-dev.eagleprojects.cloud",
  prodUrl:"https://app.yourfill.it",
  production: false,
  mapbox: {
    accessToken: 'pk.eyJ1IjoiZnZpbGxpIiwiYSI6ImNrZ2trdnF3ODJkNHEydHMxOG9pMmtoYW4ifQ.ULmUTeTrUwBKYwvjr4QesQ'
  },
  MAP_FRAME_PERC: 1,
  MAP_UPDATE_DEBOUNCE_MS: 200,
  firebase : {
    apiKey: "AIzaSyDijX0eArcBAnX1wRFfZaDONO3VkvkhNeo",
    authDomain: "yfl-develop.firebaseapp.com",
    projectId: "yfl-develop",
    storageBucket: "yfl-develop.appspot.com",
    messagingSenderId: "601244900888",
    appId: "1:601244900888:web:63cc24266c6d70f8172d6d"
  },
  hostname: 'localhost'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
