import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IUser } from 'common_library';
import { UserService } from 'src/app/services/entities/user.service';
import { UserProfileComponent } from '../master-detail-user-profile/user-profile.component';

@Component({
  selector: 'yf-profile-deletion',
  templateUrl: './profile-deletion.component.html',
  styleUrls: ['./profile-deletion.component.scss'],
})
export class ProfileDeletionComponent implements OnInit {

  @Input() user: IUser;

  constructor(private userService: UserService, private modalController: ModalController) { 
    
  }

  ngOnInit() {}

  async deleteProfile() {
    if (this.user) {
      await this.userService.deleteProfileConfirmed(this.user)
    }
  }

  async goBack() {
    await this.modalController.dismiss();
    if (this.user) {
      const modal = await this.modalController.create({
        component: UserProfileComponent,
        componentProps: {user:this.user, masterDetailShow: 'detail'},
        cssClass: 'yf-master-detail-modal' 
      })
      await modal.present();
    }
  }
}
