import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Keyboard, KeyboardInfo } from '@capacitor/keyboard';
import { IonFooter, IonModal, ModalController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core/components';
import { TranslateService } from '@ngx-translate/core';
import { IUpdateUserDTO, IUser, PlatformType } from 'common_library';
import { MyFormlyHelpers } from 'src/app/my-formly/my-formly.helpers';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UserService } from 'src/app/services/entities/user.service';
import { MainStateService } from 'src/app/services/state/app-main-state.service';
import { AlertService } from 'src/app/services/utils/alert.service';
import { ToastService } from 'src/app/services/utils/toast.service';

type UserFormType = {
  email: string;
  name?: string;
  surname?: string;
};

@Component({
  selector: 'app-modal-complete-name',
  templateUrl: 'modal-complete-name.component.html',
  styleUrls: ['./modal-complete-name.component.scss'],
})
export class ModalCompleteNameComponent implements OnInit {

  @ViewChild(IonModal) modal: IonModal;
  @ViewChild(IonFooter, { read: ElementRef }) footer: ElementRef;

  constructor(
    public modalCtrl: ModalController,
    private alert: AlertService,
    public auth: AuthService,
    private toast: ToastService,
    private userService: UserService,
    public mainStateService: MainStateService,
    private translate: TranslateService
  ) {
    if (this.mainStateService.getNative()) {
      this.listenKeyboardVisibility();
    }

  }

  user: IUser;
  userFrm: MyFormlyHelpers.FormlyForm<UserFormType>;

  get saveButtonState() {
    if (this.userFrm.form.dirty && !this.userFrm.form.valid) {
      return true;
    }
    return false
  }

  ngOnInit() {
    this.user = this.mainStateService.getUser();
    if (this.user) {
      this.userFrm = new MyFormlyHelpers.FormlyForm<UserFormType>(
        {
          email: this.user.email,
          name: this.user.name,
          surname: this.user.surname,
        },
        [
          MyFormlyHelpers.emailField({
            disabled: true,
          }),
          MyFormlyHelpers.inputField('name', this.translate.instant("PROFILE.USER_DETAIL.NAME")),
          MyFormlyHelpers.inputField('surname', this.translate.instant("PROFILE.USER_DETAIL.SURNAME")),
        ]
      );
    }
    this.alert.presentYfAlert({
      header: this.translate.instant("PROFILE.USER_DETAIL.COMPLETE_PROFILE"),
      message:
        this.translate.instant("PROFILE.USER_DETAIL.ADD_NAME_AND_SURNAME"),
      image: 'competa_profilo_illustrazione.svg',
      buttons: ['Ok'],
    });
  }

  cancel() {
    this.userFrm.options.resetModel()
  }

  confirm() {

  }

  onWillDismiss(event: Event) {
    const ev = event as CustomEvent<OverlayEventDetail<string>>;
    if (ev.detail.role === 'confirm') {
    }
  }

  async update() {
    if (!this.userFrm.form.valid) {
      return;
    }

    try {
      const dto: IUpdateUserDTO = {
        email: this.userFrm.model.email,
        name: this.userFrm.model.name.trim(),
        surname: this.userFrm.model.surname.trim(),
        // disabled: this.settingsFrm.model.disabled,
        // settings: {
        //   canUploadPhotos: this.settingsFrm.model.canUploadPhotos,
        //   canReview: this.settingsFrm.model.canReview,
        // },
      };

      const user = await this.userService.updateMe(dto);
      this.mainStateService.setUser(user);
      this.toast.presentBasicToast({
        message: this.translate.instant("PROFILE.USER_EDIT.USER_UPDATED"),
        color: 'secondary',
        icon: 'person-outline'
      });
      this.closeMe();
    } catch (err) {
      this.toast.presentBasicToast({ message: this.translate.instant("PROFILE.USER_EDIT.CANT_UPDATE_USER"), color: 'danger', icon: 'warning' });
    }
  }

  async closeMe() {
    await this.modalCtrl.dismiss();
  }

  listenKeyboardVisibility() {
    Keyboard.addListener('keyboardDidShow', (info: KeyboardInfo) => {
      //this.footer.nativeElement.classList.add('hide-footer')
      this.footer.nativeElement.style.setProperty(
        'visibility',
        'hidden');
      this.footer.nativeElement.style.setProperty(
        'opacity',
        '0');
    });
  }
}