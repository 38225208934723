import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { CU, ISession, State } from 'common_library';
@Component({
    selector: 'yf-session-details',
    templateUrl: './session-details.component.html',
    styleUrls: ['./session-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SessionDetailsComponent implements OnInit {

    @Input() session: ISession;

    countdownHHMM:string = null;
    chargingHHMM:string = null;
    parkingHHMM:string = null;
    
    constructor(private CDR: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.refresh();
        setInterval(this.refresh.bind(this),500);
    }

    refresh() {
        switch(this.session.state) {
            case State.RESERVED: {
                const et = this.session.bookingEndAt;
                const secs = et ? ((new Date(et)).getTime() - (new Date()).getTime())/1000 : 0;
                this.countdownHHMM = CU.secsToHHMM(secs);
            }
            break;

            case State.CHARGING: {
                const st = this.session.startedAt;
                const secs = st ? ((new Date()).getTime() - (new Date(st)).getTime())/1000 : 0;
                this.chargingHHMM = CU.secsToHHMM(secs);
            }
            break;

            case State.OCCUPIED: {
                const st = this.session.fullyChargedAt || this.session.stoppedAt;
                const secs = st ? ((new Date()).getTime() - (new Date(st)).getTime())/1000 : 0;
                this.parkingHHMM = CU.secsToHHMM(secs);
            }
            break;

            default:
                
                break;
        }

        
        this.CDR.detectChanges();
    }

}


