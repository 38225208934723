import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { RxState } from '@rx-angular/state';
import { BehaviorSubject, Observable } from 'rxjs';
import { GlobalState, GLOBAL_RX_STATE } from 'src/app/app.module';
import { AppService } from '../app.service';

// TODO: Sostituire .toPromise()

@Injectable({ providedIn: 'root' })
export class HttpIoService {
  private readonly _basectrl;
  public communicating$ = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient, @Inject(GLOBAL_RX_STATE) private globalState: RxState<GlobalState>, @Inject('BACKEND_URL') private backendUrl: string,) {
    const apiUrl = `${backendUrl}/api`;
    this.globalState.set({ apiUrl });
    this._basectrl = apiUrl.trim().endsWith('/') ? apiUrl.trim() : `${apiUrl.trim()}/`;
    this.globalState.connect("httpComm", this.communicating$);
  }

  async patchWithoutResponse<DTO>(ctrl: string, data: DTO): Promise<void> {
    await this.http.patch(this.endPoint(ctrl), data).toPromise();
  }

  async patch<RES, DTO>(ctrl: string, data: DTO): Promise<RES> {
    return await this.http.patch<RES>(this.endPoint(ctrl), data).toPromise();
  }

  async postWithoutResponse<DTO>(ctrl: string, data: DTO): Promise<void> {
    await this.http.post(this.endPoint(ctrl), data).toPromise();
  }

  async post<RES, DTO>(ctrl: string, data: DTO): Promise<RES> {
    this.communicating$.next(true);
    const rv = await this.http.post<RES>(this.endPoint(ctrl), data).toPromise();
    this.communicating$.next(false);
    return rv;
  }

  async getWithoutResponse(ctrl: string): Promise<void> {
    this.communicating$.next(true);
    await this.http.get(this.endPoint(ctrl)).toPromise();
    this.communicating$.next(false);
  }

  async get<RES>(ctrl: string): Promise<RES> {
    this.communicating$.next(true);
    const rv = await this.http.get<RES>(this.endPoint(ctrl)).toPromise();
    this.communicating$.next(false);
    return rv;
  }

  async externalGet<RES>(url: string): Promise<RES> {
    this.communicating$.next(true);
    const rv = await this.http.get<RES>(url).toPromise();
    this.communicating$.next(false);
    return rv;
  }

  item$<T>(ctrl: string, id: string | number): Observable<T> {
    return this.http.get<T>(`${this.endPoint(ctrl)}/${id}`);
  }

  items$<T>(ctrl: string): Observable<T[]> {
    return this.http.get<T[]>(this.endPoint(ctrl));
  }

  /**
   * Esegue una chiamata GET a {URL_API}/{ctrl}/{id}
   * @param {string} ctrl - è l'url del controller
   * @param {string} id - è l'identificativo della risorsa
   */
  async fetchItem<T>(ctrl: string, id: string | number): Promise<T> {
    this.communicating$.next(true);
    const rv = await this.item$<T>(ctrl, id).toPromise();
    this.communicating$.next(false);
    return rv;
  }

  async fetchItems<T>(ctrl: string): Promise<T[]> {
    this.communicating$.next(true);
    const rv = await this.items$<T>(ctrl).toPromise();
    this.communicating$.next(false);
    return rv;
  }

  async postItem<T, DTO>(ctrl: string, dto: DTO): Promise<T> {
    this.communicating$.next(true);
    const rv = await this.http.post<T>(this.endPoint(ctrl), dto).toPromise();
    this.communicating$.next(false);
    return rv;
  }

  async patchItem<T, DTO>(ctrl: string, id: string | number, dto: DTO): Promise<T> {
    this.communicating$.next(true);
    const rv = await this.http.patch<T>(`${this.endPoint(ctrl)}/${id}`, dto).toPromise();
    this.communicating$.next(false);
    return rv;
  }

  async deleteItem(ctrl: string, id: string | number): Promise<any> {
    this.communicating$.next(true);
    const rv = await this.http.delete(`${this.endPoint(ctrl)}/${id}`).toPromise();
    this.communicating$.next(false);
    return rv;
  }

  /**
   * This method returns the url to controller with the correct backend url, based on the app enviroment
   * 
   * @param ctrl : the path to the controller
   * @returns : correct path
   */
  public endPoint(ctrl: string): string {
    let _ctrl = ctrl.trim();
    _ctrl = _ctrl.startsWith('/') ? _ctrl.substring(1) : _ctrl;
    return `${this._basectrl}${_ctrl}`;
  }
}
