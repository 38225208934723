import { Injectable } from "@angular/core";
import { IInstallation } from "common_library";

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  sortInstallationStationsConnectors(i: IInstallation): IInstallation {
    i.stations.sort((a, b) => a.name.localeCompare(b.name));
    i.stations.forEach(s => s.connectors.sort((a, b) => a.power - b.power));
    return i;
  }
}