import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-textarea',
  templateUrl: './formly-textarea.component.html',
  styleUrls: ['../formly.global.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyTextareaComponent extends FieldType implements OnInit {
  // formControl: FormControl;

  get ngControl() {
    return this.formControl as UntypedFormControl;
  }

  ngOnInit() {}
}
