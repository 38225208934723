// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.value-container {
  justify-content: space-between;
  width: 250px;
  margin: 0 auto;
  padding-top: 6px;
}
.value-container ion-text {
  font-size: 10px;
  font-weight: 600;
  width: 46px;
  text-align: center;
}

ion-range {
  padding: 0;
  --bar-background: #ced1db;
  --bar-background-active: var(--ion-color-secondary);
  --bar-height: 4px;
  --knob-background: radial-gradient(circle, rgba(255, 255, 255, 1) 40%, rgba(41, 126, 178, 1) 60%);
  --knob-box-shadow: var(--yf-shadow);
  --knob-size: 28px;
}
ion-range ion-icon {
  color: #a2a5ad;
}
ion-range ion-icon:first-child {
  margin-right: 24px;
}
ion-range ion-icon:nth-child(2) {
  margin-left: 24px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/filter/power-range/power-range.component.scss"],"names":[],"mappings":"AAAA;EACE,8BAAA;EACA,YAAA;EACA,cAAA;EACA,gBAAA;AACF;AAAE;EACE,eAAA;EACA,gBAAA;EACA,WAAA;EACA,kBAAA;AAEJ;;AAEA;EACE,UAAA;EACA,yBAAA;EACA,mDAAA;EACA,iBAAA;EACA,iGAAA;EACA,mCAAA;EACA,iBAAA;AACF;AAAE;EACE,cAAA;AAEJ;AAAE;EACE,kBAAA;AAEJ;AAAE;EACE,iBAAA;AAEJ","sourcesContent":[".value-container {\n  justify-content: space-between;\n  width: 250px;\n  margin: 0 auto;\n  padding-top: 6px;\n  ion-text {\n    font-size: 10px;\n    font-weight: 600;\n    width: 46px;\n    text-align: center;\n  }\n}\n\nion-range {\n  padding: 0;\n  --bar-background: #ced1db;\n  --bar-background-active: var(--ion-color-secondary);\n  --bar-height: 4px;\n  --knob-background: radial-gradient(circle, rgba(255, 255, 255, 1) 40%, rgba(41, 126, 178, 1) 60%);\n  --knob-box-shadow: #{var(--yf-shadow)};\n  --knob-size: 28px;\n  ion-icon {\n    color: #a2a5ad;\n  }\n  ion-icon:first-child {\n    margin-right: 24px;\n  }\n  ion-icon:nth-child(2) {\n    margin-left: 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
