// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cheddar-outer-container {
  height: 16px;
  display: flex;
  justify-content: center;
  cursor: grab;
}
.cheddar-outer-container:active {
  cursor: grabbing;
}

.cheddar-container {
  height: 65px;
  position: absolute;
  width: 70%;
  padding: 8px 0 0 0;
  z-index: 12;
}
.cheddar-container .cheddar-content {
  background-color: lightgray;
  margin: auto;
  height: 4px;
  width: 60px;
  border-radius: 25px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/cheddar/cheddar.component.scss"],"names":[],"mappings":"AACA;EACE,YAAA;EACA,aAAA;EACA,uBAAA;EACA,YAAA;AAAF;AACE;EAAU,gBAAA;AAEZ;;AAAA;EACE,YAAA;EACA,kBAAA;EACA,UAAA;EACA,kBAAA;EACA,WAAA;AAGF;AAFE;EACE,2BAAA;EACA,YAAA;EACA,WAAA;EACA,WAAA;EACA,mBAAA;AAIJ","sourcesContent":["\n.cheddar-outer-container {\n  height: 16px;\n  display: flex;\n  justify-content: center;\n  cursor: grab;\n  &:active {cursor: grabbing;}\n}\n.cheddar-container {\n  height: 65px;\n  position: absolute;\n  width: 70%;\n  padding: 8px 0 0 0;\n  z-index: 12;\n  & .cheddar-content {\n    background-color: lightgray;\n    margin: auto;\n    height: 4px;\n    width: 60px;\n    border-radius: 25px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
