import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'yf-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() label: string;

  @Input() buttonPosition: 'right' | 'left' = 'right';
  @Input() leftButtonName: string = 'indietro';
  @Output() leftButtonEvent = new EventEmitter();

  @Input() rightButtonName: string = 'close';
  @Output() rightButtonEvent = new EventEmitter();

  constructor() {}

  ngOnInit() {}
}
