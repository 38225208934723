// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yf-grid-container {
  padding: 6px !important;
  gap: 9px !important;
  justify-content: center;
}

.yf-grid-item {
  width: 108px;
  max-width: unset;
  max-height: unset;
}`, "",{"version":3,"sources":["webpack://./src/app/components/station/station-connector-selector/station-connector-selector.component.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,mBAAA;EACA,uBAAA;AACF;;AACA;EACE,YAAA;EACA,gBAAA;EACA,iBAAA;AAEF","sourcesContent":[".yf-grid-container{\n  padding: 6px !important;\n  gap: 9px !important;\n  justify-content: center;\n}\n.yf-grid-item{\n  width: 108px;\n  max-width: unset;\n  max-height: unset;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
