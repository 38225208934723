import { Component, EventEmitter, Output, Inject, computed } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { environment } from 'src/environments/environment';
import { GLOBAL_RX_STATE, GlobalState } from 'src/app/app.module';
import { RxState } from '@rx-angular/state';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { MenuItem, menuItems } from 'src/app/app.menu';
import { IUser } from 'common_library';
import { TranslateService } from '@ngx-translate/core';
import { MainStateService } from 'src/app/services/state/app-main-state.service';

@Component({
  selector: 'yf-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
})
export class MainMenuComponent {
  isDevelop$ = new BehaviorSubject<boolean>(false);
  $appVersion = computed(() => {
    return this.mainStateService.$version()?.frontendVersion;
  });
  
  readonly clientId: string;
  readonly BACKEND_URL = environment.url  

  items$: Observable<MenuItem[]>;
  @Output() itemClicked: EventEmitter<MenuItem> = new EventEmitter<MenuItem>();

  constructor(
    @Inject(GLOBAL_RX_STATE) private globalState: RxState<GlobalState>,
    public auth: AuthService,
    public mainStateService: MainStateService,
    private translate: TranslateService
  ) {
    this.isDevelop$.next(this.globalState.get().isDevelop);
    //this.clientId = this.globalState.get().clientId.substring(0, 13);
  }

  $items = computed(() => {
    let user = this.mainStateService.$user();
    return menuItems.filter(item => item.needsToBeLogged === undefined || item.needsToBeLogged === !!user)
  });

  itemClick(item: MenuItem) {
    this.itemClicked.emit(item);
  }
}
