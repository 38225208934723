import { Inject, Injectable } from '@angular/core';
import {
  ClientEvents,
  getNamespacesArray,
  ServerEvents,
  WsAckResponse,
  WsNamespaces,
} from 'common_library';
import { BehaviorSubject, fromEvent, map, Observable, Subject, debounceTime, startWith } from 'rxjs';
import { Manager, ManagerOptions, Socket, SocketOptions } from 'socket.io-client';
import { GlobalState, GLOBAL_RX_STATE } from 'src/app/app.module';
import { RxState } from '@rx-angular/state';
import { MainState } from 'src/app/types/state/app-main-state.interface';

@Injectable({
  providedIn: 'root',
})
export class SocketIoService {
  private manager: Manager;

  public socket: Socket;
  public sockets: { [key in WsNamespaces]?: Socket } = {};
  public connected$ = new BehaviorSubject<boolean>(false);

  private _disconnected$ = new Subject<void>();
  private _reconnect$ = new Subject<boolean>();
  public disconnected$ = this._disconnected$.asObservable();
  private _socketAuthError$ = new Subject<void>();
  public socketAuthError$ = this._socketAuthError$.asObservable();
/* 
  public disconnectedIo$ = this.connected$.pipe(
    map((connected) => !connected),
    startWith(!this.connected$.getValue())
  ) */

  public connectedNamespaces: Map<WsNamespaces, BehaviorSubject<boolean>> = new Map<
    WsNamespaces,
    BehaviorSubject<boolean>
  >();

  public communicating$ = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject('SOCKET_IO_ADDRESS') private address: string,
    @Inject(GLOBAL_RX_STATE) private globalState: RxState<GlobalState>,
  ) {

    this.globalState.connect(
      this.connected$.pipe(
        map(connected => connected ? { mainState: MainState.ONLINE } : { mainState: MainState.OFFLINE })
      ));

    this.globalState.connect("wsComm", this.communicating$);

    this.connect();

    this._reconnect$.pipe(
      debounceTime(7000),
    ).subscribe(() => {
      console.log("Mi riconnetto")
      this.connect();
    });
  }

  get socketOpts(): Partial<ManagerOptions & SocketOptions> {
    return {
      autoConnect: false,
      forceNew: false,
      transports: ['websocket'],
      reconnection: false,
    }
  }

 connect() {
    if (this.socket?.disconnected === false) {
      return;
    }
    this.manager = new Manager(`${this.address}`, this.socketOpts);
    this.setupSocket();

    this.socket.connect();
  }

  disconnect() {
    console.log('[SOCKET-IO] - Forcing disconnection');
    this.socket.disconnect();
    Object.values(this.sockets).forEach(s => s.disconnect());
    this._disconnected$.next();
    this.connected$.next(false);
  }


  setupSocket() {
    getNamespacesArray().forEach(topic => {
      console.log('creating socket for namespace', topic);
      this.sockets[topic] = this.manager.socket(`/${topic}`, this.socketOpts)
      this.sockets[topic].on('connect_error', (message) => {
        console.log('[SOCKET] - error trying to connect to socket', topic, message);
      });
      this.connectedNamespaces.set(topic, new BehaviorSubject<boolean>(false));
      this.sockets[topic].on('connect', async () => {
        this.connectedNamespaces.get(topic).next(true);
        this.connected$.next(true)
      });
    });

    this.socket = this.manager.socket('/', this.socketOpts);

    this.socket.on('connect', async () => {
      console.log("[SOCKET] - CONNECTED")
      Object.values(this.sockets).forEach(socket => socket.connect());
      this.connected$.next(true)
    });

    this.socket.on('reconnect', async () => {

    })

    this.socket.on('disconnect', async (e) => {
      console.log("[SOCKET] - DISCONNECTED", e)
      Object.values(this.sockets).forEach(socket => socket.off())
      this._disconnected$.next();
      this.connected$.next(false);
      this._reconnect$.next(true);
    });

    this.socket.on('reconnecting_attempt', () => { console.log('trying to reconnect...') });

    this.socket.on('connect_error', async (message) => {
      console.log("SOCKET CONNECT ERROR", message.name, message.message, message);
      this.connected$.next(false);
      this._reconnect$.next(true);

      const castedError = message as unknown as {
        type: string;
        description: number | string;
      };
      if (castedError.type === 'TransportError' && castedError.description == 403) {
        console.log('SOCKET FORBIDDEN');
        this._socketAuthError$.next();
      }
      if (message.name == 'Error' && message.message === 'xhr poll error') {
        console.log('Cannot Establish socket connection');
      }
    });

    console.log('connecting!', this.manager)
    this.socket.connect();
  }

  sendMessage<BodyDto>(
    targetNamepace: WsNamespaces,
    topic: ClientEvents | string,
    payload: BodyDto
  ): void {
    const namespace = this.sockets[targetNamepace];
    //console.log('SIS : sendMessage :', { namespace, topic });
    if (namespace) {
      namespace.emit(topic, payload);
    } else {
      throw new Error(`Error: Namespace not found`);
    }
  }

  sendRequest<BodyDto, ResDto>(
    targetNamepace: WsNamespaces,
    topic: ClientEvents | string,
    payload: BodyDto
  ): Promise<ResDto> {
    return new Promise((resolve, reject) => {
      this.communicating$.next(true);
      const namespace = this.sockets[targetNamepace];
      if (namespace) {
        namespace.timeout(30000).emit(topic, payload, (err, ack: WsAckResponse<ResDto>) => {
          this.communicating$.next(false);
          if (err) {
            console.log(topic, err);
            throw new Error(err);
          }
          if (ack.success) {
            //console.log(ack);
            resolve(ack.data);
          } else {
            throw new Error(`Error: ${ack.message}`);
          }
        });
      } else {
        throw new Error(`Error: Namespace not found`);
      }
    });
  }

  listen<T>(targetNamepace: WsNamespaces, eventName: string, filters?: any): Observable<T[]> {
    return new Observable<T[]>((observer) => {
      console.log(
        'subscribed to listener ',
        eventName,
        'on ns:',
        targetNamepace,
        'with filters',
        filters
      );
      const namespace = this.sockets[targetNamepace];
      namespace.on(eventName, (data: T[]) => {
        observer.next(data);
      });
      namespace.emit(`${eventName}`, filters);
      return () => {
        namespace.emit(`unsubscribe_${eventName}`);
        namespace.off(eventName);
      };
    });
  }

  fromEvent<T>(targetNamepace: WsNamespaces, eventName: ServerEvents): Observable<T> {
    console.log('[SIS] subscribed to event ', eventName, 'on ns:', targetNamepace)
    const namespace = this.sockets[targetNamepace];
    return fromEvent<T>(namespace, eventName);
  }
}
