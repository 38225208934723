import { IConnector, IInstallation, ISession, IStation, IUser, ICar, IAuthToken, IVersionUpdate, IInfoContent } from "common_library";
import { CmdButton } from "../buttons/button-state.interface";
import { Point } from "geojson";

export enum MainState { INIT = "INIT", ONLINE = "ONLINE", OFFLINE = "OFFLINE" }
export type Nullable<T> = T | null;
export interface ButtonState { exipresAt?: Date, command: CmdButton }
export interface IUserVisibilityGroups {
  groups: number[],
  updatedAt: Date
}

export interface AppMainState {
  user: Nullable<IUser>,
  userCars: Nullable<ICar[]>,
  userVisibilityGroups: Nullable<IUserVisibilityGroups>,
  authTokenUser: Nullable<IAuthToken>,
  sessions: Nullable<ISession[]>,

  selectedInstallation: Nullable<IInstallation>,
  selectedStation: Nullable<IStation>,
  selectedConnector: Nullable<IConnector>,
  selectedCluster: Nullable<IInstallation[]>,
  selectedSession: Nullable<ISession>,
  selectedUserCarForSession: Nullable<ICar>,
  lastSelectedConnectorId: Nullable<number>,

  buttonState: Nullable<Map<number, ButtonState>>,

  clientId: string,
  clientLastPosition: Point,
  native: boolean,
  platformType: string,
  initializationCompleted: boolean,
  searchPanelVisible: boolean,
  version: Nullable<IVersionUpdate>,

  cmsContent: Nullable<IInfoContent>
}