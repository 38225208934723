import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { KeyboardResize } from '@capacitor/keyboard';
import { ModalController } from '@ionic/angular';
import { PlatformType } from 'common_library';
import { distinctUntilChanged, fromEvent, map, startWith, Subject, takeUntil } from 'rxjs';
import { AppNativeService } from 'src/app/services/app.native.service';
import { MainStateService } from 'src/app/services/state/app-main-state.service';

const COMPACT_MODE_WIDTH = 768;

export type MasterDetailShowType = 'master' | 'detail';
export interface FilterOptions { }

@Component({
  selector: 'app-master-detail',
  templateUrl: './master-detail.component.html',
  styleUrls: ['./master-detail.component.scss'],
})
export class MasterDetailComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() icon: string = 'ellipsis-vertical-outline';
  @Input() titleMaster: string;
  @Input() titleDetail?: string = null;
  @Input() show: MasterDetailShowType = 'master';
  @Output() showChange: EventEmitter<MasterDetailShowType> = new EventEmitter<MasterDetailShowType>();
  @Input() filter: FilterOptions;
  @Input() forceCompact: boolean = false;
  //NUOVI INPUT PER INTEGRAZIONE PIPE TITOLO
  @Input() typeValue?: string;
  @Input() svg?: boolean = false;
  @Input() toolbarButtonDisabled: boolean = false;
  compactMode: boolean = false;
  @Output() compactModeChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  destroy$ = new Subject<void>();
  constructor(private modalCtrl: ModalController,
    private mainStateService: MainStateService,
    private appNativeService: AppNativeService) { }

  ngOnInit() {
    if (this.mainStateService.getNative() && this.mainStateService.getPlatformType() === PlatformType.iOSNative) {
      this.appNativeService.resizeKeyboad(KeyboardResize.None);
    }

    fromEvent(window, 'resize')
      .pipe(
        map((event) => {
          const window = event.target as Window;
          return { height: window.innerHeight, width: window.innerWidth };
        }),
        startWith({ height: window.innerHeight, width: window.innerWidth }),
        distinctUntilChanged(),
        takeUntil(this.destroy$)
      )
      .subscribe((window) => {
        this.compactMode = this.forceCompact || window.width <= COMPACT_MODE_WIDTH;
        this.compactModeChange.emit(this.compactMode);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    if (this.mainStateService.getNative()) {
      this.appNativeService.resizeKeyboad(KeyboardResize.Ionic);
    }
  }

  close() {
    this.modalCtrl.dismiss();
  }

  toggle() {
    if (this.show === 'master') {
      this.show = 'detail';
    } else {
      this.show = 'master';
    }
  }

  showMaster() {
    this.showChange.emit('master');
  }

}
