import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StoreService } from './utils/store.service';
import { _ } from '../consts';
import { Language } from 'common_library';

@Injectable({
  providedIn: 'root'
})
export class TranslateConfigService {
  constructor(
    private translate: TranslateService,
    private store: StoreService
  ) {
    this.translate.setDefaultLang(this.getDefaultLanguage());
  }

  getDefaultLanguage() {
    if (!this.store.get(_.LANG_KEY)) {
      let browserLang = this.translate.getBrowserLang();
      if (!browserLang || !this.isLanguageAllowed(browserLang)) {
        browserLang = "en";
      }
      this.setLanguage(browserLang);
    }
    return this.getCurrentLang();
  }

  setLanguage(setLang: string) {
    this.translate.use(setLang);
    this.store.set(_.LANG_KEY, setLang);
  }

  getCurrentLang(): string {
    return this.store.get(_.LANG_KEY);
  }

  forceGetCurrentLanguage(): Language {
    let langString = this.getDefaultLanguage();
    return langString as Language;
  }

  isLanguageAllowed(value: any): value is Language {
    return value === 'it' || value === 'en';
  }
}