// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.leds {
  position: absolute;
  bottom: 50%;
  left: 3px;
  z-index: 10000;
}

.led {
  width: 7px;
  height: 7px;
  border: 1px solid #FFF;
  border-radius: 3px;
  margin: 1px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/app-status-leds/app-status-leds.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,SAAA;EACA,cAAA;AACF;;AAEA;EACE,UAAA;EACA,WAAA;EACA,sBAAA;EACA,kBAAA;EACA,WAAA;AACF","sourcesContent":[".leds {\n  position: absolute;\n  bottom: 50%;\n  left: 3px;\n  z-index: 10000;\n}\n\n.led {\n  width: 7px;\n  height: 7px;\n  border: 1px solid #FFF;\n  border-radius: 3px;\n  margin: 1px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
