// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.container {
  position: absolute;
  bottom: 20px;
  right: 5px;
  z-index: 10000;
  padding: 0px;
  font-size: 9pt;
  color: rgb(233, 17, 17);
  border: 1px solid red;
  background-color: rgba(255, 0, 0, 0.24);
  width: 400px;
  height: 600px;
  overflow-y: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/components/debug-full/debug-full.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,YAAA;EACA,UAAA;EACA,cAAA;EACA,YAAA;EACA,cAAA;EACA,uBAAA;EACA,qBAAA;EACA,uCAAA;EACA,YAAA;EACA,aAAA;EACA,gBAAA;AACJ","sourcesContent":["div.container {\n    position: absolute;\n    bottom: 20px;\n    right: 5px;\n    z-index: 10000;\n    padding: 0px;\n    font-size: 9pt;\n    color: rgb(233, 17, 17);\n    border: 1px solid red;\n    background-color: rgba(255, 0, 0, 0.24);\n    width: 400px;\n    height: 600px;\n    overflow-y: auto;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
