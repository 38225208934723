// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.formly-input-container {
  padding-bottom: 8px;
  margin-bottom: 8px;
  position: relative;
}

input {
  border: none;
  border-bottom: 2px;
  border-color: #aeaeaf;
  margin: 0 8px;
  height: 36px;
  font-size: 16px;
  background: transparent;
  outline-width: 0px;
  outline: none;
}

input::placeholder {
  color: #aeaeaf;
  margin-left: 2px;
}

p {
  text-align: center;
}
p.valid:after {
  color: green;
  content: "Carta Valida";
}
p.invalid:after {
  color: red;
  content: "Carta non valida!";
}`, "",{"version":3,"sources":["webpack://./src/app/my-formly/components/cvv/formly-cvv-component.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,kBAAA;EACA,kBAAA;AACF;;AAEA;EACE,YAAA;EACA,kBAAA;EACA,qBAAA;EACA,aAAA;EACA,YAAA;EACA,eAAA;EACA,uBAAA;EACA,kBAAA;EACA,aAAA;AACF;;AAEA;EACE,cAAA;EACA,gBAAA;AACF;;AACA;EACE,kBAAA;AAEF;AAAE;EACE,YAAA;EACA,uBAAA;AAEJ;AACE;EACE,UAAA;EACA,4BAAA;AACJ","sourcesContent":[".formly-input-container {\n  padding-bottom: 8px;\n  margin-bottom: 8px;\n  position: relative;\n}\n\ninput {\n  border: none;\n  border-bottom: 2px;\n  border-color: #aeaeaf;\n  margin: 0 8px;\n  height: 36px;\n  font-size: 16px;\n  background: transparent;\n  outline-width: 0px;\n  outline: none;\n}\n\ninput::placeholder {\n  color: #aeaeaf;\n  margin-left: 2px;\n}\np {\n  text-align: center;\n\n  &.valid:after {\n    color: green;\n    content: 'Carta Valida';\n  }\n\n  &.invalid:after {\n    color: red;\n    content: 'Carta non valida!';\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
