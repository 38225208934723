// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.star-rating-filled {
  stroke-width: 1px;
}

.star-rating-blank {
  fill: transparent;
  stroke-width: 1px;
}

svg {
  width: 16px;
  height: 16px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/star-container/star/star.component.scss"],"names":[],"mappings":"AAAA;EAGE,iBAAA;AADF;;AAGA;EACE,iBAAA;EAEA,iBAAA;AADF;;AAGA;EACE,WAAA;EACA,YAAA;AAAF","sourcesContent":[".star-rating-filled {\n  // fill: var(--star-color);\n  // stroke: var(--star-color);\n  stroke-width: 1px;\n}\n.star-rating-blank {\n  fill: transparent;\n  // stroke: var(--star-color);\n  stroke-width: 1px;\n}\nsvg {\n  width: 16px;\n  height: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
