import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions, FormlyTemplateOptions } from '@ngx-formly/core';
import { luhnCheck } from './components/credit-card/luhn.helper';
import { TranslateService } from '@ngx-translate/core';

export namespace MyFormlyHelpers {
  export class FormlyForm<T> {
    ready = false;
    form = new UntypedFormGroup({});

    constructor(
      public model: T,
      public fields: FormlyFieldConfig[] = [],
      public options: FormlyFormOptions = {}
    ) { }

    setup() {
      this.ready = true;
    }
  }

  export function selectField(
    key: string,
    label: string,
    templateOptions: FormlyTemplateOptions,
    expressionProperties?: {},
    defaultValue?: string,
  ) {
    return {
      key,
      type: 'f-select',
      templateOptions: {
        label,
        required: true,
        ...templateOptions,
      },
      default: defaultValue,
      expressionProperties,
    };
  }

  export function ckeckboxField(key: string, label: string): FormlyFieldConfig {
    return {
      key,
      type: 'f-checkbox',
      templateOptions: {
        label,
        required: true,
      },
    };
  }

  export function inputField(key: string, label: string, required: boolean = true, defaultValue?: any): FormlyFieldConfig {
    return {
      key,
      type: 'f-input',
      parsers: [(value: string) => value?.replace(/\s+/g, ' ').trimLeft()],
      templateOptions: {
        label,
        type: 'text',
        required: required,
      },
      defaultValue
    };
  }

  export function textarea(
    key: string,
    label: string,
    row?: number,
    col?: number,
    placeholder?: string
  ): FormlyFieldConfig {
    return {
      key,
      type: 'f-textarea',
      templateOptions: {
        label,
        row,
        col,
        placeholder,
        type: 'text',
        required: true,
      },
    };
  }

  export function passwordField(confirm?: boolean, required: boolean = true, label?: string): FormlyFieldConfig {
    return {
      key: `password${confirm ? 'Confirm' : ''}`,
      type: 'f-input',
      parsers: [(value: string) => value?.trim()],
      templateOptions: {
        // autocomplete: 'new-password',
        label: `${confirm ? 'Conferma' : ''} Password`,
        type: 'password',
        // placeholder: `${confirm ? 'Conferma' : 'Inserire qui'} la password`,
        required: required,
        minLength: confirm ? null : 6,
      },
    };
  }

  export function emailField(templateOptions?: FormlyTemplateOptions): FormlyFieldConfig {
    return {
      key: 'email',
      type: 'f-input',
      templateOptions: {
        ...templateOptions,
        label: 'Email',
        type: 'email',
        required: true,
      },
      validators: {
        validation: [EmailValidator],
      },
    };
  }

  export function confirmPasswordFields(required?: boolean, label?: string): FormlyFieldConfig {
    return {
      validators: {
        validation: [{ name: 'fieldMatch', options: { errorPath: 'passwordConfirm' } }],
      },
      fieldGroup: [passwordField(false, required), passwordField(true, required, label)],
    };
  }

  export function emailValidationMessage(err, field: FormlyFieldConfig) {
    return `"${field.formControl.value}" non è un email valida.`;
  }

  export function samePasswordValidationMessage(err, field: FormlyFieldConfig) {
    return `Le password non corrispondono.`;
  }

  export function minlengthValidationMessages(err, field: FormlyFieldConfig) {
    return `La ${field.key} deve avere almeno ${field.templateOptions.minLength} caratteri`;
  }

  export function EmailValidator(c: UntypedFormControl, f: FormlyFieldConfig): ValidationErrors {
    return !c.value || /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(c.value)
      ? null
      : { email: true };
  }

  export function PasswordMatchValidator(control: AbstractControl) {
    const { password, passwordConfirm } = control.value;

    // avoid displaying the message error when values are empty
    if (!passwordConfirm || !password) {
      return null;
    }

    if (passwordConfirm === password) {
      return null;
    }

    return { fieldMatch: { message: 'Le password inserite non corrispondono' } };
  }

  export function luhnValidator(control: AbstractControl) {
    if (control.value) {
      const isValid = luhnCheck(control.value);
      return isValid ? null : { luhnCheck: isValid };
    } else {
      return null;
    }
  }

  export function formlyValidationConfig(translate: TranslateService) {
    return {
      validationMessages: [
        {
          name: 'required',
          message() {
            return translate.stream('FORM.VALIDATION.REQUIRED');
          },
        }
      ],
    };
  }
}
