// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.container {
  position: absolute;
  bottom: -1px;
  right: 2px;
  z-index: 10000;
  padding: 0px;
  font-size: 6pt;
  color: #888;
}`, "",{"version":3,"sources":["webpack://./src/app/components/debug/debug.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,YAAA;EACA,UAAA;EACA,cAAA;EACA,YAAA;EACA,cAAA;EACA,WAAA;AACJ","sourcesContent":["div.container {\n    position: absolute;\n    bottom: -1px;\n    right: 2px;\n    z-index: 10000;\n    padding: 0px;\n    font-size: 6pt;\n    color: #888;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
