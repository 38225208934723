import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'yf-stop-charge',
  templateUrl: './stop-charge.component.html',
  styleUrls: ['./stop-charge.component.scss'],
})
export class StopChargeComponent implements OnInit {

  constructor(
    private translate: TranslateService) { }

  ngOnInit() { }
}
