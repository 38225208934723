export interface IStationButtonState {
  reservation: IButtonState,
  start: IButtonState,
  stop: IButtonState,
  cancel: IButtonState
}

export interface IButtonState {
  show: boolean;
  disabled: boolean;
}

export enum CmdButton {
  Reserve,
  CancelReservation,
  StartCharge,
  StopCharge,
  EndSession,
  Login,
}