import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CarFilters, IMapBounds, PlugType } from 'common_library';
import { _ } from 'src/app/consts';
import { CoreService } from 'src/app/services/core.service';
import { StoreService } from 'src/app/services/utils/store.service';

import { AuthService } from '../../services/auth/auth.service';
import { AppService } from 'src/app/services/app.service';

const DEFAULT_FILTER: CarFilters = {
  quick: [],
  carId: null,
  plugs: [],
  range: null,
}

@Component({
  selector: 'yf-filter-modal',
  templateUrl: './filter.page.html',
  styleUrls: ['./filter.page.scss'],
})
export class FilterPage implements OnInit {
  filterModel: CarFilters = DEFAULT_FILTER;
  @Output() filterChange = new EventEmitter<IMapBounds>();

  constructor(
    public auth: AuthService,
    public CS: CoreService,
    private store: StoreService,
    private appService: AppService) {
      
  }

  async ngOnInit() {
/*     this.CS.selectedCar$.subscribe((car) => {
      // if (car) {
      //   this.filterModel = car.filters || DEFAULT_FILTER;
      // } else {
      const savedFilters = this.store.get<CarFilters>(_.SELECTED_FILTER);
      if (savedFilters) {
        this.filterModel = savedFilters;
        this.appService.checkFilterState();
      } else {
        this.filterModel = { quick: [], carId: null, plugs: [...Object.values(PlugType)], range: null };
        this.appService.checkFilterState();
      }
      // }
    });
    console.log('FilterPage : ngOnInit : this.filterModel', this.filterModel); */
  }

  async updateModel(event, type: string) {
    switch (type) {
      case 'quick':
        this.filterModel.quick = event;
        break;
      // case 'car':
      //   // if (!event.isSelected) {
      //   //   await this.CS.selectCar(event.id);
      //   //   this.filterModel.carId = event.id;
      //   // }
      //   break;
      case 'plugs':
        this.filterModel.plugs = event;
        break;
      case 'range':
        this.filterModel.range = event;
        break;
      default:
        break;
    }

    if (!this.auth.isAuthenticated) {
      this.store.set(_.SELECTED_FILTER, this.filterModel);
    } else {
      this.store.set(_.SELECTED_FILTER, this.filterModel);
      // this.CS.updateCar(this.CS.selectedCar.id, { filters: this.filterModel });
    }
    this.appService.checkFilterState();
    this.filterChange.emit();
  }

  // resetFilters() {
  //   console.log('reset');
  // }

  // applyFilters() {
  //   console.log('filtri appicati');
  //   this.toast.presentBasicToast({ message: 'Filtri applicati', color: 'secondary' });
  //   this.closeMe();
  // }

  // selectVehicle(car: ICar) {
  //   console.log('🚀 : car', car);
  // }

  // async closeMe() {
  //   await this.modalCtrl.dismiss();
  // }
}
