import { Injectable } from '@angular/core';
import { HttpIoService } from '../communication/http-io.service';
import { ITariff } from 'common_library';

@Injectable({
  providedIn: 'root'
})
export class TariffService {

  constructor(
    private HIO: HttpIoService
  ) { }

  async getConnectorTariff(id: number, userId?: string): Promise<ITariff> {
    try {
      if (!userId) {
        return this.HIO.get(`core/public/connector-tariff/${id}`);
      }
      return this.HIO.get(`core/connector-tariff/${id}`);
    } catch (error) {
      console.error('An error occurred while fetching connector tariff.');
    }
  }
}
