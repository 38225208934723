import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyCreditCardComponent } from './components/credit-card/formly-credit-card-component';

import { FormlyCheckboxComponent } from './components/formly-checkbox/formly-checkbox.component';
import { FormlyInputComponent } from './components/formly-input/formly-input.component';
import { FormlySelectComponent } from './components/formly-select.ts/formly-select.component';
import { FormlyTextareaComponent } from './components/formly-textarea/formly-textarea.component';
import { FormlyCvvComponent } from './components/cvv/formly-cvv-component';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

// const forms = [LoginFormTemplateComponent];

const components = [
  FormlyInputComponent,
  FormlyCheckboxComponent,
  FormlySelectComponent,
  FormlyTextareaComponent,
  FormlyCreditCardComponent,
  FormlyCvvComponent,
];

@NgModule({
    declarations: [...components /* ...forms */],
    imports: [
        IonicModule,
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        FormlyModule.forRoot({}),
        NgxMaskDirective,
        NgxMaskPipe,
        TranslateModule.forChild({extend: true, isolate: false}),
    ],
    providers: [provideNgxMask()],
    exports: [...components /* ...forms */]
})
export class MyFormlyModule {}
