import { Component, ViewChild, ElementRef, effect } from '@angular/core';
import { CU, ISession, OCPICdrDimensionType, State, TariffUtil } from 'common_library';
import { Observable, filter, map, of, shareReplay, timer } from 'rxjs';
import { SessionService } from 'src/app/services/entities/session.service';
import { MainStateService } from 'src/app/services/state/app-main-state.service';

@Component({
  selector: 'yf-cost-detail',
  templateUrl: './cost-detail.component.html',
  styleUrls: ['./cost-detail.component.scss'],
})
export class CostDetailComponent {
  @ViewChild('container', { static: true }) container!: ElementRef;

  elements$: Observable<any>;
  constructor(
    private mainStateService: MainStateService,
    private sessionService: SessionService,
  ) {
    effect(() => {
      this.setElements(this.mainStateService.$selectedConnectorMineSession());
    });
  }

  setElements(session: ISession) {
    if (!!session?.endedAt) return of(null);
    if (session?.state === State.CHARGING) {
      this.elements$ = of(this.sessionService.timeForElements(session));
    }
    else if (!!session?.reservedAt || !!session?.stoppedAt) {
      this.elements$ = timer(0, 10000).pipe(
        filter(() => !!session),
        map(() => {
          session = this.sessionService.calculateCost(session);
          return this.sessionService.timeForElements(session);
        }),
        shareReplay({ bufferSize: 1, refCount: true })
      )
    }
  }
}
