// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --yf-border-radius: 12px;
  --yf-shadow: 5px 5px 10px #0000001f;
  --yf-spacing0: 6px;
  --yf-spacing1: 12px;
  --yf-spacing2: 24px;
  --yf-spacing3: 36px;
  --yf-spacing4: 48px;
  --yf-spacing5: 60px;
  --yf-primary-light: #9edaff;
  --yf-primary: #297eb2;
  --yf-primary-dark: #174c6d;
  --yf-secondary: #0d2c40;
  --yf-accent: #c2fc84;
  --yf-white: #FFFF;
}

.plug-button {
  height: 56px;
  width: auto;
  flex-basis: 25%;
  --border-radius: 0px;
  --box-shadow: transparent;
  --padding-start: 0;
  --padding-end: 0;
  --padding-bottom: 0;
  --padding-top: 0;
  margin: 0;
}
.plug-button ion-icon {
  font-size: 28px;
}`, "",{"version":3,"sources":["webpack://./src/theme/sass/abstracts/variables.scss","webpack://./src/app/components/filter/filter-connector-selector/filter-connector-selector.component.scss"],"names":[],"mappings":"AAAA;EACE,wBAAA;EACA,mCAAA;EACA,kBAAA;EACA,mBAAA;EACA,mBAAA;EACA,mBAAA;EACA,mBAAA;EACA,mBAAA;EAEA,2BAAA;EACA,qBAAA;EACA,0BAAA;EACA,uBAAA;EACA,oBAAA;EACA,iBAAA;ACAF;;AAbA;EACE,YAAA;EACA,WAAA;EACA,eAAA;EACA,oBAAA;EACA,yBAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,gBAAA;EACA,SAAA;AAgBF;AAfE;EACE,eAAA;AAiBJ","sourcesContent":[":root {\n  --yf-border-radius: 12px;\n  --yf-shadow: 5px 5px 10px #0000001f;\n  --yf-spacing0: 6px;\n  --yf-spacing1: 12px;\n  --yf-spacing2: 24px;\n  --yf-spacing3: 36px;\n  --yf-spacing4: 48px;\n  --yf-spacing5: 60px;\n\n  --yf-primary-light: #9edaff;\n  --yf-primary: #297eb2;\n  --yf-primary-dark: #174c6d;\n  --yf-secondary: #0d2c40;\n  --yf-accent: #c2fc84;\n  --yf-white: #FFFF;\n}\n","@import './../../../../theme/sass/abstracts/variables.scss';\n\n.plug-button {\n  height: 56px;\n  width: auto;\n  flex-basis: 25%;\n  --border-radius: 0px;\n  --box-shadow: transparent;\n  --padding-start: 0;\n  --padding-end: 0;\n  --padding-bottom: 0;\n  --padding-top: 0;\n  margin: 0;\n  ion-icon {\n    font-size: 28px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
