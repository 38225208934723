// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  display: flex;
  overflow-x: scroll;
  height: 90px;
}

::-webkit-scrollbar {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/app/components/cost-detail/cost-detail.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;EACA,YAAA;AACF;;AAEA;EACE,aAAA;AACF","sourcesContent":[".container {\n  display: flex;\n  overflow-x: scroll;\n  height: 90px;\n}\n\n::-webkit-scrollbar{\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
