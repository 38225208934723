import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'yf-unpaid-session-banner',
  templateUrl: './unpaid-session-banner.component.html',
  styleUrls: ['./unpaid-session-banner.component.scss'],
})
export class UnpaidSessionBannerComponent implements OnInit {
  @Output() payNow: EventEmitter<void> = new EventEmitter();
  constructor(
    private translate: TranslateService
  ) {
    
  }

  ngOnInit() {}

  payNowClicked() {
    this.payNow.emit();
  }
}
