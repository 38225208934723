import { Component, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MainStateService } from 'src/app/services/state/app-main-state.service';
import { ButtonStateService } from 'src/app/services/state/button-state.service';

@Component({
  selector: 'yf-avvia-prenotazione',
  template: `
    <ion-button [disabled]="this.buttonStateService.$buttonStartReservation().disabled" (click)="reserve()" class="btn large grow" [ngClass]="{
              primary: !this.mainStateService.$selectedConnectorHasMineSession(),
              disabled: this.buttonStateService.$buttonStartReservation().disabled
            }"
            debounceClick>
      <ion-icon slot="start" src="assets/app-icons/cronometro.svg"></ion-icon>
      {{ "APP.INSTALLATION.BUTTONS.RESERVE" | translate }}
  </ion-button>
  `,
})
export class AvviaPrenotazioneComponent {
  @Output() reserveEvent: EventEmitter<void> = new EventEmitter<void>();
    
  constructor(
    public mainStateService: MainStateService,
    public buttonStateService: ButtonStateService,
    private translate: TranslateService
  ) {
    
  }

  reserve() {
    this.reserveEvent.emit();
  }
}