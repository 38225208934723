import { Injectable } from '@angular/core';
import { Geolocation, Position, PermissionStatus } from '@capacitor/geolocation';

@Injectable({
    providedIn: 'root',
})

export class GeoLocationNativeService {
    private _hasGeoloc = false;
    get hasGeoloc(): boolean { return this._hasGeoloc; };

    private _permissionState: PermissionStatus = null;
    get permissionState(): PermissionStatus { return this._permissionState };

    constructor () {

    }

    async getCurrentPosition(): Promise<Position> {
        return await Geolocation.getCurrentPosition({enableHighAccuracy: true});
    }

    async requestPermissions(): Promise<PermissionStatus> {        
        this._permissionState = await Geolocation.requestPermissions();
        if(this._permissionState.location === 'granted') {
            this._hasGeoloc = true;
        } else {
            this._hasGeoloc = false;              
        } 
        return this._permissionState;
    }

    async checkPermission() {
        this._permissionState = await Geolocation.checkPermissions();       
    }
}