import { Component, EventEmitter, Inject, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Geolocation, Geoposition, PositionError } from '@awesome-cordova-plugins/geolocation/ngx';
import { Platform } from '@ionic/angular';
import { RxState } from '@rx-angular/state';
import { IMapBounds, IInstallation, StateValues } from 'common_library';
import { Geometry, Position } from 'geojson';
import * as maplibregl from 'maplibre-gl';
import { Map, Marker, StyleSpecification } from 'maplibre-gl';
import { Subscription } from 'rxjs';
import { GlobalState, GLOBAL_RX_STATE } from 'src/app/app.module';
import { _ } from 'src/app/consts';
import { SocketIoService } from 'src/app/services/communication/socket-io.service';
import { environment } from 'src/environments/environment';

import { StoreService } from '../../services/utils/store.service';
import { ToastService } from 'src/app/services/utils/toast.service';
import { Geolocation as Geo } from '@capacitor/geolocation';
import { GeoLocationNativeService } from 'src/app/services/native/geo-location-native.service';
import { TranslateService } from '@ngx-translate/core';
import { MainStateService } from 'src/app/services/state/app-main-state.service';

export interface MapPosition { lat: number, lng: number, showLocationMarker: boolean }
export interface MapItem { type: MapItemType, geometry: Geometry, properties?: InstallationProperties | ClusterProperties }
export enum MapItemType { INSTALLATION, CLUSTER, BACKGROUND }
export interface InstallationProperties { id: string, state: number, power: number, cpo: string }
export interface ClusterProperties { count: number, ids: string[] }
export interface IMapSettings {
  icons: string[]
}

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, OnDestroy, OnChanges {
  // PRIVATE -------------------------------------------------------------------------------------
  _ready = false;
  _style: string | StyleSpecification = 'https://tiles.eagleprojects.cloud/styles/basic-preview/style.json';
  _zoom = 15;
  _center: [number, number] = this.store.get<Position>(_.GEOLOCATION_KEY) ? [this.store.get<Position>(_.GEOLOCATION_KEY)[0], this.store.get<Position>(_.GEOLOCATION_KEY)[1]] : [12.457264824422932, 41.90223757871921]; // città del vaticano
  _selectedInstallation: IInstallation | undefined;
  _map: Map | undefined;
  _me: Marker | undefined;
  _selectedStationMarker: Marker | undefined;
  _shownMap: IMapBounds;
  _dbto: any;
  _data: GeoJSON.FeatureCollection<GeoJSON.Point> = { type: 'FeatureCollection', features: [] };
  subscription: Subscription;

  geoLocateCtrl: maplibregl.GeolocateControl;
  geoLocateCtrlHide = true;
  _locationMarker: maplibregl.Marker;

  // PUBLIC -------------------------------------------------------------------------------------

  @Input() data: GeoJSON.FeatureCollection<GeoJSON.Point> = { type: 'FeatureCollection', features: [] };
  @Input() updates: InstallationProperties[] = [];
  @Input() center: MapPosition;
  @Input() selectedInstallation: IInstallation;
  @Input() reload: boolean = false;

  @Output() mapReady = new EventEmitter<boolean>();
  @Output() mapBounds = new EventEmitter<IMapBounds>();
  @Output() clickItem = new EventEmitter<MapItem>();

  readonly native: boolean;  

  constructor(private platform: Platform,
    private geolocation: Geolocation,
    private store: StoreService,
    public SIS: SocketIoService,
    private ngZone: NgZone,
    private toast: ToastService,
    private geoLocationNative: GeoLocationNativeService,
    @Inject(GLOBAL_RX_STATE) private globalState: RxState<GlobalState>,
    private mainStateService: MainStateService,
    private translate: TranslateService) {
    this.native = this.mainStateService.getNative();
  }

  async ngOnInit() {
    console.log("map ngOnInit start");
    await this.platform.ready();
    let pos = this._center;

    // Se riesco a prendere le coordinate dal device, punto la mappa su quelle
    try {
      let geopos: Geoposition;
      if (this.native) {
        geopos = await this.geoLocationNative.getCurrentPosition();
      } else {
        geopos = await this.geolocation.getCurrentPosition();
      }
      if (geopos) {
        pos = [geopos.coords.longitude, geopos.coords.latitude];
      }
    } catch {
      console.log("Autorizzazione non concessa");
    }

    this.store.set(_.GEOLOCATION_KEY, pos);

    if (this.native) {
      Geo.watchPosition({ enableHighAccuracy: false, maximumAge: 5000, timeout: 10000 }, (position, err) => {
        if (position) {
          const pos: Geoposition = <Geoposition>position;
          console.log("map component -> watch position")
          this.store.set(_.GEOLOCATION_KEY, [pos.coords.longitude, pos.coords.latitude]);
        }
      });
    } else {
      this.geolocation.watchPosition().subscribe(async (data: Geoposition | PositionError) => {
        console.log("geolocation.watchPosition");
        if ((<PositionError>data).code) {
          this.toast.presentBasicToast({
            // header: 'Abilitare la localizzazione',
            header: this.translate.instant('TOAST_MESSAGES.ENABLE_LOCALIZATION_TITLE'),
            message: this.translate.instant('TOAST_MESSAGES.ENABLE_LOCALIZATION_MESSAGE'),
            color: 'secondary',
            icon: 'location-outline'
          });
        }
        else {
          const pos: Geoposition = <Geoposition>data;
          console.log("map component -> watch position")
          this.store.set(_.GEOLOCATION_KEY, [pos.coords.longitude, pos.coords.latitude]);
        }
      });
    }

    console.log("map ngOnInit end");
  }

  async onLoad(mapInstance: Map) {
    console.log("MAP.COMPONENT:onLoad")
    this.globalState.set({ mapLoaded: false });

    this._map = mapInstance;
    this._map.setRenderWorldCopies(false);

    await this._map.addLayer({
      id: 'clusters',
      type: 'circle',
      source: 'dataSource',
      filter: ['has', 'count'],
      paint: {
        'circle-color': [
          'step',
          ['get', 'count'],
          '#fff',
          10, '#efefff',
          20, '#dfdfff',
          30, '#cfcfff',
          40, '#4f4',
          50, '#ddf',
          60, '#aaf',
          70, '#88f',
          80, '#44f',
          90, '#fede71',
          100, '#fdd',
          125, '#faa',
          150, '#f88',
          175, '#f44',
          200, '#ffd',
          300, '#ffa',
          400, '#ff8',
          500, '#ff4',
          750, '#999',
          1000, '#777',
          2000, '#555'   // tutte quelle con count maggiore a 20 hanno sfondo '#aaaaff'
        ],
        'circle-radius': [
          'step',
          ['get', 'count'],
          15,     // tutte quelle con count inferiore a 10 hanno raggio 15
          499, 20,
          999, 25,  // tutte quelle con count maggiore a 10 hanno raggio 20
          1999, 30   // tutte quelle con count maggiore a 20 hanno raggio 25
        ]
      }
    });

    await this._map.addLayer({
      id: 'clusters-count',
      type: 'symbol',
      source: 'dataSource',
      filter: ['has', 'count'],
      layout: {
        'text-field': '{count}',
        'text-font': ['Noto Sans Regular'],
        'text-size': 12
      }
    });

    await this._map.addLayer({
      id: 'installations',
      type: 'symbol',
      source: 'dataSource',
      filter: ['!has', 'count'],
      layout: {
        "icon-image": "{cpo}_{state}",
        "icon-size": 0.6,
        "icon-allow-overlap": true,
        "icon-anchor": "bottom",
      },
    });

    //Caricamento immagini
    const images = [
      'yfl_0', 'yfl_1', 'yfl_2', 'yfl_3', 'yfl_4', 'yfl_5',
      'enx_0', 'enx_1', 'enx_2', 'enx_3', 'enx_4', 'enx_5',
      'ftx_0', 'ftx_1', 'ftx_2', 'ftx_3', 'ftx_4', 'ftx_5',
      'not_yfl_0', 'not_yfl_1', 'not_yfl_2', 'not_yfl_3', 'not_yfl_4', 'not_yfl_5'];

    for (const image_name of images) {
      let image = await this._map.loadImage(`assets/stations/markers/${image_name}.png`);
      this._map.addImage(`${image_name}`, image.data, { sdf: false });
    }

    let debounceClick;

    this._map.on('click', (e) => {
      this.ngZone.run(() => {
        debounceClick = setTimeout(() => {
          console.log('A click BACKGROUND event was emitted', e);
          this.clickItem.emit({ type: MapItemType.BACKGROUND, geometry: { type: "Point", coordinates: [e.lngLat.lng, e.lngLat.lat] } })
        }, 100);
      });
    });

    this._map.on('click', 'clusters', (e) => {
      this.ngZone.run(() => {
        if (debounceClick) clearTimeout(debounceClick);
        console.log('A click clusters event has occurred', e);
        this.clickItem.emit({ type: MapItemType.CLUSTER, geometry: e.features[0].geometry, properties: <ClusterProperties>e.features[0].properties })
      });
    });

    this._map.on('click', 'installations', (e) => {
      this.ngZone.run(() => {
        if (debounceClick) clearTimeout(debounceClick);
        const installation = <InstallationProperties>e.features[0].properties;
        //Molto importante per prendere i gruppi di punti vicini?
        // const features = this._map.queryRenderedFeatures({layers: ['installations']});
        // if(features.length) {
        //   if(features[0].id === installation.id) {
        //     const s = this._data.features.find(f => f.id === installation.id);
        //   }
        // }
        // const index = this._data.features.findIndex(x => x.properties.id === installation.id);
        // features[0].layer.layout.visibility = 'none';
        // this._data.features[index].properties.cpo = 'not_yfl'
        // this._map.setLayoutProperty('installations', 'icon-image',
        //     [
        //       'match',
        //       ['propierties']['id'], // get the feature id (make sure your data has an id set or use generateIds for GeoJSON sources
        //       this._data.features[index].properties.id, 'enx', //image when id is the clicked feature id
        //        'not_yfl' // default
        //     ]
        // )
        this.showSelectedMarker(installation.cpo, installation.state as StateValues, (<any>e.features[0].geometry).coordinates);
        //Puo' essere utile?
        // this._map.flyTo({
        //   center: [(<any>e.features[0]._geometry).coordinates[0], (<any>e.features[0]._geometry).coordinates[1]  - (2/1000)],
        //   zoom: 15,
        //   essential: true
        // })
        console.log('A click INSTALLATION event was emitted', installation, e.features[0]);
        this.clickItem.emit({ type: MapItemType.INSTALLATION, geometry: e.features[0].geometry, properties: installation });
      });
    });

    /*
    this._map.on('idle', function () {
      console.log('MAP -> A idle event occurred.');
    });
*/

    this._map.on('error', function () {
      console.log('MAP -> A error event occurred.');
    });

    this._map.on('mouseenter', 'clusters', () => { this._map.getCanvas().style.cursor = 'pointer'; });
    this._map.on('mouseleave', 'clusters', () => { this._map.getCanvas().style.cursor = ''; });

    this._map.on('mouseenter', 'installations', () => { this._map.getCanvas().style.cursor = 'pointer'; });
    this._map.on('mouseleave', 'installations', () => { this._map.getCanvas().style.cursor = ''; });

    const station_selector = document.createElement('div');
    station_selector.style.width = "29px";
    station_selector.style.height = "34px";
    station_selector.style.border = "1px solid blue";
    station_selector.style.borderRadius = "6px";
    station_selector.style.visibility = "hidden";
    this._selectedStationMarker = new Marker({ anchor: 'bottom', element: station_selector }).setLngLat(<maplibregl.LngLatLike>this._center).addTo(this._map);

    // Disabilito la rotazione dalla mappa perchè crea problemi
    this._map.dragRotate.disable();
    this._map.touchZoomRotate.disableRotation();

    this._map.resize();

    this.showGeolocate();

    let self = this;

    var interval = setInterval(function () {
      var mapLoaded = self._map.loaded();
      if (mapLoaded) {
        clearInterval(interval);
        self.globalState.set({ mapLoaded: true });
        console.log("MAP.COMPONENT:map loaded!");
      }
    }, 500);
  }

  onClick(evt) {
    console.log('map click', evt);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.updates) {
      const sps: InstallationProperties[] = changes.updates.currentValue;
      if (sps.length === 0) return;

      if (this._data) {
        for (const sp of sps) {
          const s = this._data.features.find(f => f.properties.id === sp.id);
          if (s) {
            s.properties.state = sp.state;
          }
        }
        this._data = { ...this._data };
      }
    }

    if (changes.data) {
      this._data = changes.data.currentValue;
    }

    if (changes.center && !!changes.center.currentValue) {

      console.log("changes.center", changes.center.currentValue);
      // Utilizzato per la ricerca: quando inserisco la via, mi restituisce le coordinate lat lng e in quel caso metto un marker rosso

      if (this._locationMarker) this._locationMarker.remove();
      const c: MapPosition = changes.center.currentValue;

      if (c.showLocationMarker) {

        let locationMarker = document.createElement('div');
        locationMarker.classList.add('location_marker');

        const iconName = 'location_marker.svg';

        locationMarker.style.backgroundImage = `url('./../../../assets/app-icons/${iconName}')`;
        locationMarker.style.width = '29px';
        locationMarker.style.height = '34px';
        locationMarker.style.cursor = 'pointer';
        locationMarker.style.opacity = '1';

        this._locationMarker = new Marker(locationMarker).setLngLat([c.lng, c.lat])
          .addTo(this._map);
      }

      this._map.flyTo({ center: [c.lng, c.lat], zoom: 15 });
    }

    if (changes.selectedInstallation) {            
      this.manageSelectedInstallation(changes.selectedInstallation.currentValue);
    }
  }

  followMe() {
    const delay = 1000;
    if (!this.native) {
      setTimeout(() => {
        this.geoLocateCtrl?.trigger();
      }, delay)
    }
    if (this.native && this.geoLocationNative?.permissionState?.location === 'granted') {
      setTimeout(() => {
        this.geoLocateCtrl.trigger();
      }, delay)
    }
  }

  // Evento Move è sollevato ogni volta cambia l'inquadratura della mappa (sia per traslazione che zoom)
  onMove(evt: any) {
    if (this._dbto) clearTimeout(this._dbto); // debounce
    this._dbto = setTimeout(() => {
      this.reloadBounds();
    }, environment.MAP_UPDATE_DEBOUNCE_MS);
  }

  reloadBounds() {
    const bounds = this.getBounds(environment.MAP_FRAME_PERC);
    this._shownMap = bounds;
    //if (this._shownMap) {
    this.mapBounds.emit(bounds);
    // }
  }

  onGeolocate(position: any) {
    console.log('geolocate', position);
  }

  // frame è quello che voglio in più in percentuale
  getBounds(frame: number): IMapBounds {
    if (!this._map) return;

    const canvas = this._map.getCanvas();
    const w = canvas.width / this._map.getPixelRatio();
    const h = canvas.height / this._map.getPixelRatio();
    const wF = w * frame / 2;
    const hF = h * frame / 2;
    const c00 = this._map.unproject([-wF, -hF]).toArray();
    const cWH = this._map.unproject([w + wF, h + hF]).toArray();

    const bounds = {
      lat: { min: Math.min(c00[1], cWH[1]), max: Math.max(c00[1], cWH[1]) },
      lng: { min: Math.min(c00[0], cWH[0]), max: Math.max(c00[0], cWH[0]) },
      zoom: this._map.getZoom()
    };
    return bounds;
  }

  manageSelectedInstallation(installation: IInstallation) {
    if (installation) {
      const installationMarkerAlreadyInMap = this._data.features.find(f => f.properties.id === installation.id);
      if (!installationMarkerAlreadyInMap) {
        // Se l'installazione proviene da un deeplink devo disabilitare il geolocate altrimenti la mappa viene riportata alla localizzazione corrente
        if (this.globalState.get("installationByDeeplink")) {
          this.showGeolocate(false);
          this.globalState.set({ installationByDeeplink: false });
        }

        this._map.stop();
        this._map.jumpTo({ center: [installation.coord.coordinates[0], installation.coord.coordinates[1]], padding: { left: 0, top: 0, bottom: 300, right: 0 }, zoom: 15 });
        this.showSelectedMarker(null, installation.state, installation.coord.coordinates);
      }
    }
    else {
      this.hideSelectedMarker();
    }
  }

  showSelectedMarker(cpo: string, state: StateValues, coordinates: Position) {
    this._selectedStationMarker.setLngLat([coordinates[0], coordinates[1]]);
    this._selectedStationMarker.getElement().style.visibility = "visible";
  }

  hideSelectedMarker() {
    if (this._selectedStationMarker) this._selectedStationMarker.getElement().style.visibility = "hidden";
  }

  public showGeolocate(trackUserLocation: boolean = true) {
    this.hideGeolocate();
    let self = this;

    this.geoLocateCtrl = new maplibregl.GeolocateControl({
      showUserLocation: true,
      showAccuracyCircle: false,
      positionOptions: { enableHighAccuracy: true },
      trackUserLocation: trackUserLocation
    });

    this.geoLocateCtrl.on('geolocate', function () {
      var userlocation = self.geoLocateCtrl._lastKnownPosition;
      if (userlocation) {
        self.store.set(_.GEOLOCATION_KEY, [userlocation.coords.longitude, userlocation.coords.latitude]);
      }
    });

    this.geoLocateCtrl.on('error', async (e) => {
      if (this.native) {
        this.toast.presentBasicToast({
          header: 'Abilitare la localizzazione',
          message: 'Abilitando la localizzazione possiamo mostrarti i punti di ricarica più vicini',
          color: 'secondary',
          duration: 6000,
          icon: 'location-outline'
        });
      }
    })

    if (this.geoLocateCtrlHide) {
      var interval = setInterval(function () {
        var mapLoaded = self._map.loaded();
        if (mapLoaded) {
          clearInterval(interval);
          self.geoLocateCtrlHide = false;
          self._map.addControl(self.geoLocateCtrl, 'bottom-right');
          if (trackUserLocation) {
            let pos = self.store.get<Position>(_.GEOLOCATION_KEY);
            if (pos) {
              self._map.flyTo({ center: [pos[0], pos[1]] });
            }
            self.followMe();
          }
        }
      }, 500);
    }
  }

  hideGeolocate() {
    if (this.geoLocateCtrl && !this.geoLocateCtrlHide) {
      this.geoLocateCtrlHide = true;
      try {
        this._map.removeControl(this.geoLocateCtrl)
      }
      catch { }
    }
  }
}
