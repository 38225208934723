import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Camera, CameraResultType, CameraSource, CameraDirection, Photo } from '@capacitor/camera';
import { Platform } from '@ionic/angular';
import { IPhoto } from 'common_library';
import { AppService } from '../app.service';

export const IMAGE_DIR = 'stored-image';

@Injectable({
  providedIn: 'root',
})
export class PhotoService {
  constructor(private http: HttpClient, private platform: Platform, private app: AppService) {}

  public async capturePhoto(): Promise<Blob> {
    // Take a photo
    const image = await Camera.getPhoto({
      resultType: CameraResultType.Uri,
      allowEditing: false,
      source: CameraSource.Camera,
      quality: 90,
      direction: CameraDirection.Rear,
    });

    if (image) {
      const blob = await this.convertToBlob(image);
      return blob;
    }
  }

  async convertToBlob(photo: Photo) {
    const response = await fetch(photo.webPath);
    const blob = await response.blob();
    return blob;
  }

  async uploadPhoto(stationId: string, file: Blob) {
    const formData = new FormData();
    formData.append('file', file);

    const res = await this.http
      .post<IPhoto | { error: boolean }>(`${this.app.apiUrl}/photo/create/${stationId}`, formData)
      .toPromise();
    /* 
    PER PROGRESSO UPLOAD
    , {
      reportProgress: true,
      observe: 'events'
    }); */
    return res;
  }

  async uploadUserPhoto(file: Blob) {
    const formData = new FormData();
    formData.append('file', file);

    const res = await this.http
      .post<IPhoto | { error: boolean }>(`${this.app.apiUrl}/photo/upload-user-photo`, formData)
      .toPromise();
    /* 
    PER PROGRESSO UPLOAD
    , {
      reportProgress: true,
      observe: 'events'
    }); */
    return res;
  }

  async getAllByStationId(stationId: string) {
    const res = await this.http
      .get<IPhoto[]>(`${this.app.apiUrl}/photo?stationId=${stationId}`)
      .toPromise();
    return res.map((v) => {
      return { ...v, path: `${this.app.apiUrl}/photo/${v.id}` };
    });
  }

  async deletePhoto(id: string) {
    try {
      await this.http.delete<IPhoto[]>(`${this.app.apiUrl}/photo/${id}`).toPromise();
    } catch (error) {
      console.log('🚀 : error', error);
    }
  }

  // async readAsBase64(photo:Photo){
  //   // if(this.platform.is('hybrid')){
  //   //   const file = await Filesystem.readFile({
  //   //     path: photo.path
  //   //   });
  //   //   return file.data;
  //   // }else{
  //     // const response = await fetch(photo.webPath);
  //     console.log(photo);

  // const response = await fetch(photo.webPath);
  // const blob = await response.blob();
  //     console.log('BLOB: ', blob);

  //     // return await this.convertBlobToBase64(blob) as string;
  //   }
}

// convertBlobToBase64 = (blob:Blob) => new Promise((resolve, reject) =>{
//   const reader = new FileReader;
//   reader.onerror = reject;
//   reader.onload = () => {
//     resolve(reader.result);
//   }
//   reader.readAsDataURL(blob);
// });
