import { Injectable } from '@angular/core';
import { ModalController, ToastController, ToastOptions } from '@ionic/angular';
import { SessionsComponent } from 'src/app/components/master-detail-sessions/sessions.component';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  public toast: HTMLIonToastElement = null;

  constructor(
    public toastController: ToastController,
    private modalController: ModalController,
    private translate: TranslateService) { 
      
    }

  async presentBasicToast(to: ToastOptions) {
    if (this.toast) {
      this.toast.dismiss();
      this.toast = null;
    }
    this.toast = await this.toastController.create({
      message: to.message,
      header: to.header,
      color: to.color || 'primary',
      duration: to.duration || 5000,
      cssClass: to.cssClass || 'yfl-toast',
      position: to.position || 'top',
      icon: to.icon || 'information-circle-outline'
    });
    this.toast.present();
  }

  dismissToast() {
    if (this.toast) {
      this.toast.dismiss();
      this.toast = null;
    }
  }

  async presentUnpaidSessionToast() {
    const sessionUnpaidToast = await this.toastController.create({
      message: this.translate.instant("APP.INSTALLATION.TO_START_ANOTHER_SESSION_THE_PREVIOUS_MUST_BE_PAID_CORRECTLY"),
      color: 'danger',
      icon: 'warning',
      duration: 5000,
      position: 'top',
      buttons: [{ role: 'add', text: this.translate.instant("BUTTONS.PAY_NOW"), }]
    });
    await sessionUnpaidToast.present();
    await sessionUnpaidToast.onDidDismiss().then(async (res) => {
      if (res.role === 'add') {
        const sessionsModal = await this.modalController.create({
          component: SessionsComponent,
          cssClass: 'yf-master-detail-modal',
        });
        await sessionsModal.present();
      }
    });
  }
}
