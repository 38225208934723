// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  height: 28px;
  line-height: 28px;
}

.icon {
  font-size: 24px;
  vertical-align: middle;
  margin-right: 2px;
  color: black;
}

.value {
  font-size: 20px;
  vertical-align: middle;
  margin: 2px;
  font-weight: bold;
  color: black;
}

.title-value {
  font-size: 20px;
  vertical-align: middle;
  margin: 2px;
  font-weight: bold;
  color: #297EB2;
}

.no-marked {
  font-weight: 400 !important;
  font-size: 14px !important;
}

.text {
  font-size: 14px;
  vertical-align: middle;
  margin: 2px;
  font-weight: bold;
  color: black;
}

.label {
  font-size: 14px;
  vertical-align: middle;
  margin: 2px;
  color: #333;
}`, "",{"version":3,"sources":["webpack://./src/app/components/value/value.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,iBAAA;AACJ;;AAEA;EACI,eAAA;EACA,sBAAA;EACA,iBAAA;EACA,YAAA;AACJ;;AAEA;EACI,eAAA;EACA,sBAAA;EACA,WAAA;EACA,iBAAA;EACA,YAAA;AACJ;;AAEA;EACI,eAAA;EACA,sBAAA;EACA,WAAA;EACA,iBAAA;EACA,cAAA;AACJ;;AAEA;EACI,2BAAA;EACA,0BAAA;AACJ;;AAEA;EACI,eAAA;EACA,sBAAA;EACA,WAAA;EACA,iBAAA;EACA,YAAA;AACJ;;AAEA;EACI,eAAA;EACA,sBAAA;EACA,WAAA;EACA,WAAA;AACJ","sourcesContent":[".container {\n    height:28px;\n    line-height:28px;\n}\n\n.icon {\n    font-size: 24px;\n    vertical-align:middle; \n    margin-right: 2px;\n    color:black;\n}\n\n.value {\n    font-size: 20px; \n    vertical-align:middle;  \n    margin: 2px; \n    font-weight: bold;\n    color:black;\n}\n\n.title-value {\n    font-size: 20px; \n    vertical-align:middle;  \n    margin: 2px; \n    font-weight: bold;\n    color: #297EB2;\n}\n\n.no-marked {\n    font-weight: 400 !important;\n    font-size: 14px !important;\n}\n\n.text {\n    font-size: 14px; \n    vertical-align:middle;  \n    margin: 2px; \n    font-weight: bold;\n    color:black;\n}\n\n.label {\n    font-size: 14px; \n    vertical-align:middle;  \n    margin: 2px;\n    color:#333;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
