// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo-container {
  background: var(--ion-color-light);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}
.logo-container .logoImg {
  padding: 20%;
}`, "",{"version":3,"sources":["webpack://./src/app/components/logo-thumbnail/logo-thumbnail.component.scss"],"names":[],"mappings":"AAAA;EACE,kCAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,mBAAA;AACF;AACE;EACE,YAAA;AACJ","sourcesContent":[".logo-container {\n  background: var(--ion-color-light);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border-radius: 10px;\n\n  .logoImg {\n    padding: 20%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
