import { Component, Input, input } from '@angular/core';

@Component({
  selector: 'yf-charge-speed-info',
  templateUrl: './charge-speed-info.component.html',
  styleUrls: ['./charge-speed-info.component.scss'],
})
export class ChargeSpeedInfoComponent  {
  @Input() power: number;
  $textClass = input<string>('fs-13');
  $umClass = input<string>('fs-11');

  constructor() { }

  $power = input<number>(null);
}
