import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
  computed,
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { State } from 'common_library';
import { AuthService } from 'src/app/services/auth/auth.service';
import { NavigateToPointService } from 'src/app/services/utils/navigate-to-point.service';
import { RfidDetailsComponent } from '../../master-details-rfid/rfid-details.component';
import { RxState } from '@rx-angular/state';
import { TranslateService } from '@ngx-translate/core';
import { ButtonStateService } from 'src/app/services/state/button-state.service';
import { MainStateService } from 'src/app/services/state/app-main-state.service';
import { CmdButton } from 'src/app/types/buttons/button-state.interface';

@Component({
  selector: 'yf-station-buttons',
  templateUrl: './station-buttons.component.html',
  styleUrls: ['./station-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState]
})
export class StationButtonsComponent {

  readonly $chargingFinished = computed(() => {
    const selectedSession = this.mainStateService.$selectedConnectorMineSession();
    return !!selectedSession && selectedSession.state === State.OCCUPIED;
  });

  @Output() clickButton = new EventEmitter<CmdButton>();

  constructor(
    public auth: AuthService,
    public navService: NavigateToPointService,
    private modalCtrl: ModalController,
    private translate: TranslateService,
    public mainStateService: MainStateService,
    public buttonStateService: ButtonStateService
  ) {
    
  }

  login() {
    this.clickButton.emit(CmdButton.Login);
  }

  reserve() {
    if (this.buttonStateService.$buttonStartReservation().disabled) return;
    this.clickButton.emit(CmdButton.Reserve);
  }

  cancelReservation() {
    if (this.buttonStateService.$buttonCancelReservation().disabled) return;
    this.clickButton.emit(CmdButton.CancelReservation);
  }

  startCharge() {
    if (this.buttonStateService.$buttonStartCharge().disabled) return;
    this.clickButton.emit(CmdButton.StartCharge);
  }

  stopCharge() {
    if (this.buttonStateService.$buttonStopCharge().disabled) return;
    this.clickButton.emit(CmdButton.StopCharge);
  }

  endSession() {
    this.clickButton.emit(CmdButton.EndSession);
  }

  async showRfidModal() {
    const modal = await this.modalCtrl.create({
      component: RfidDetailsComponent,
      cssClass: 'yf-master-detail-modal',
      componentProps: {
        station: this.mainStateService.$selectedStation(),
      }
    });
    return await modal.present();
  }
}
