// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.master {
  min-width: 360px;
}

.detail {
  min-width: 360px;
}

.yf-master-detail .master {
  width: 100% !important;
}

.yf-master-detail .detail {
  width: 100% !important;
}

.master-item {
  font-size: medium;
  color: var(--ion-color-secondary);
  border: 1px solid rgb(99, 99, 99);
  padding: 10px;
}

.detail {
  height: 100%;
  min-width: 360px;
}

.detail-item {
  font-size: medium;
  color: var(--ion-color-secondary);
  padding: 10px;
  height: 100%;
}

.list-item {
  border-radius: 8px;
}

.selected {
  background: #f2f2f9;
}

.item-icon {
  margin-inline-end: 10px !important;
  font-size: 12px;
}

.notification-type-icon {
  margin-inline-end: 10px !important;
  font-size: 26px;
}

ion-card {
  padding-top: 0px;
}

ion-item-divider {
  padding-left: 0px;
}

ion-toolbar {
  --background: none;
}

.section-title {
  font-weight: bold;
  font-size: large;
}

.section-subtitle {
  font-size: smaller;
  color: #666;
}

.arrived {
  color: #92949c;
}

.title-item {
  color: #297EB2;
  font-size: 16px;
}

ion-content {
  --padding-bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/components/master-details-notification/notification-details.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AAEA;EACI,sBAAA;AACJ;;AAEA;EACI,sBAAA;AACJ;;AAEA;EACI,iBAAA;EACA,iCAAA;EACA,iCAAA;EACA,aAAA;AACJ;;AAEA;EACI,YAAA;EACA,gBAAA;AACJ;;AAEA;EACI,iBAAA;EACA,iCAAA;EAEA,aAAA;EACA,YAAA;AAAJ;;AAGA;EACI,kBAAA;AAAJ;;AAGA;EACI,mBAAA;AAAJ;;AAGA;EACI,kCAAA;EACA,eAAA;AAAJ;;AAEA;EACE,kCAAA;EACA,eAAA;AACF;;AAEA;EACI,gBAAA;AACJ;;AAEA;EACI,iBAAA;AACJ;;AACA;EACI,kBAAA;AAEJ;;AACA;EACI,iBAAA;EACA,gBAAA;AAEJ;;AACA;EACI,kBAAA;EACA,WAAA;AAEJ;;AACA;EACI,cAAA;AAEJ;;AACA;EACE,cAAA;EACA,eAAA;AAEF;;AACA;EACE,mBAAA;AAEF","sourcesContent":[".master{\n    min-width: 360px;\n}\n\n.detail{\n    min-width: 360px;\n}\n\n.yf-master-detail  .master{\n    width: 100% !important;\n}\n\n.yf-master-detail  .detail{\n    width: 100% !important;\n}\n\n.master-item {\n    font-size: medium;\n    color: var(--ion-color-secondary);\n    border: 1px solid rgb(99, 99, 99);\n    padding: 10px;\n}\n\n.detail {\n    height: 100%;\n    min-width: 360px;\n}\n\n.detail-item {\n    font-size: medium;\n    color: var(--ion-color-secondary);\n    //border-left: 1px solid #eee;\n    padding: 10px;\n    height: 100%;\n}\n\n.list-item {\n    border-radius: 8px;\n}\n\n.selected {\n    background: #f2f2f9;\n}\n\n.item-icon{\n    margin-inline-end: 10px !important;\n    font-size: 12px;\n}\n.notification-type-icon{\n  margin-inline-end: 10px !important;\n  font-size: 26px;\n}\n\nion-card{\n    padding-top: 0px;\n}\n\nion-item-divider{\n    padding-left: 0px;\n}\nion-toolbar{\n    --background: none;\n}\n\n.section-title {\n    font-weight: bold;\n    font-size: large;\n}\n\n.section-subtitle {\n    font-size: smaller;\n    color:#666;\n}\n\n.arrived {\n    color: #92949c;\n}\n\n.title-item {\n  color: #297EB2;\n  font-size: 16px;\n}\n\nion-content {\n  --padding-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
