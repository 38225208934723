import { Component, OnDestroy, OnInit } from '@angular/core';
import { ICar, IUser } from 'common_library';
import { BehaviorSubject } from 'rxjs';
import { CarService } from 'src/app/services/entities/car.service';
import { MasterDetailShowType } from '../master-detail/master-detail.component';
import { TranslateService } from '@ngx-translate/core';
import { MainStateService } from 'src/app/services/state/app-main-state.service';

export enum DetailTypes {
  User,
  Car,
  None
}

enum DetailTitles {
  User = 'Modifica profilo',
  Car = 'Modifica veicolo'
}

@Component({
  selector: 'yf-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit, OnDestroy {
  readonly DetailTypes = DetailTypes;
  readonly DetailTitles = DetailTitles;
  detailType: DetailTypes = DetailTypes.User;

  masterDetailShow: MasterDetailShowType = 'master';
  compactMode: boolean = false;
  user: IUser;
  selectedCar$: BehaviorSubject<ICar> = new BehaviorSubject(null);
  titleDetail: DetailTitles;
  savingNewCar: boolean = false;

  constructor(
    public mainStateService: MainStateService,
    public carService: CarService,
    private translate: TranslateService
  ) { }

  get newVehicle() {
    return this.carService.newVehicle;
  }

  set newVehicle(car) {
    this.carService.newVehicle = car;
  }

  async ngOnInit() {
    if (!!this.newVehicle) {
      this.savingNewCar = true;
      this.showDetail(DetailTypes.Car, DetailTitles.Car, null);
    }
  }

  ngOnDestroy(): void {
    if (!this.newVehicle?.model) {
      this.newVehicle = null;
      this.savingNewCar = false;
    }
  }

  compactModeChange(compactMode: boolean) {
    this.compactMode = compactMode;
  }

  showDetail(detailType: DetailTypes, titleType: DetailTitles, car?: ICar) {

    if (car?.id && detailType === DetailTypes.Car) {
      this.selectedCar$.next(car);
    } else if (detailType === DetailTypes.Car && this.savingNewCar) {
      this.selectedCar$.next(null)
    } else if (detailType === DetailTypes.Car && !car) {
      this.selectedCar$.next(null)
      this.savingNewCar = true;
    }

    this.detailType = detailType;
    this.masterDetailShow = 'detail';
    this.setDetailTitle(titleType);
  }

  handleCancelEvent(car?: any) {
    if (!car) {
      this.selectedCar$.next(null);
    } else {
      this.selectedCar$.next(car || this.user.cars[0])
    }
    this.savingNewCar = false;
    this.newVehicle = null;
  }
  handleDeleteEvent() {
    this.masterDetailShow = 'master';
    this.selectedCar$.next(this.user.cars[0])
  }

  handleFormChanges(form) {
    this.newVehicle = form;
    this.selectedCar$.next(form)
  }

  selectCar(carId: number) {
    this.carService.selectCar(carId);
  }

  setDetailTitle(titleType: DetailTitles) {
    let title: string;
    if (titleType === DetailTitles.User) {
      title = 'PROFILE.USER_DETAIL.EDIT';
    } else {
      title = 'CAR.CAR_DETAIL.EDIT';
    }
    this.translate.get(title).subscribe((res: string) => {
      this.titleDetail = res as DetailTitles;
    });
  }
}
