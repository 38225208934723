import { Injectable } from '@angular/core';
import { INotification, RecordCounted } from 'common_library';
import { BehaviorSubject, distinctUntilChanged } from 'rxjs';
import { HttpIoService } from '../communication/http-io.service';

const CONTROLLER_ROOT = 'notification';

@Injectable({ providedIn: 'root' })
export class NotificationService {
  private _notification$ = new BehaviorSubject<INotification[]>(null);
  notification$ = this._notification$.asObservable().pipe(distinctUntilChanged());
  selectedNotification$ = new BehaviorSubject<INotification>(null);
  userNotificationCount = 0;

  constructor(private HIO: HttpIoService) {
  }

  async fetchNotifications(): Promise<INotification[]> {
    try {
      let rv: INotification[] = await this.HIO.fetchItems<INotification>(`${CONTROLLER_ROOT}/user-notifications`);;
      this._notification$.next(rv)
      return rv
    } catch (error) {
      console.log('🐱️ : error', error);
    }
  }

  async fetchNotificationsPaged(currentPage: number, pageSize: number): Promise<RecordCounted<INotification>> {
    try {
      const rv: RecordCounted<INotification> = await this.HIO.get(`${CONTROLLER_ROOT}/user-notification-paged?currentPage=${currentPage}&pageSize=${pageSize}`)
      return rv;
    } catch (error) {
      console.error('🐱️ : error', error);
    }
  }

  async selectNotification(id: number): Promise<void> {
    try {
      await this.HIO.getWithoutResponse(`${CONTROLLER_ROOT}/select/${id}`);
      await this.fetchNotifications();
    } catch (error) {
      console.log('🐱️ : error', error);
    }
  }

  async update(notificationId: string, dto: any): Promise<INotification> {
    try {
      const notification = await this.HIO.patchItem<INotification, any>(CONTROLLER_ROOT, notificationId, dto);
      const notifications = await this.fetchNotifications();
      return notifications.find((c) => c.id === notification.id);
    } catch (error) {
      console.log('🐱️ : error', error);
    }
  }

  async updateNotification(notificationId: string, dto: any): Promise<INotification> {
    try {
      const notification = await this.HIO.patchItem<INotification, any>(CONTROLLER_ROOT, notificationId, dto);
      return notification;
    } catch (error) {
      console.log('🐱️ : error', error);
    }
  }

  async delete(notificationId: number): Promise<void> {
    try {
      await this.HIO.deleteItem(CONTROLLER_ROOT, notificationId);
      await this.fetchNotifications();
    } catch (error) {
      console.log('🐱️ : error', error);
    }
  }
}
