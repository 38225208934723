// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-menu::part(container) {
  border-radius: 0 20px 20px 0;
}

@media (max-width: 576px) {
  ion-menu {
    margin-top: env(safe-area-inset-top);
  }
}
@media (min-width: 576px) {
  ion-menu {
    margin-top: env(safe-area-inset-top);
  }
}
ion-menu ion-icon {
  font-size: 25px;
  cursor: pointer;
}

ion-header ion-toolbar {
  padding: 5px 10px 5px 10px;
  --border-width: 0;
  --background: #fff;
}
ion-header ion-title {
  padding: 0 10px 0;
  width: auto;
  font-size: 25px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.24);
}

.debug {
  position: absolute;
  bottom: 2px;
  left: 6px;
  z-index: 10000;
  padding: 0px;
  font-size: 10pt;
  color: #888;
}`, "",{"version":3,"sources":["webpack://./src/app/components/main-menu/main-menu.component.scss","webpack://./src/theme/sass/abstracts/mixins.scss"],"names":[],"mappings":"AAEA;EACE,4BAAA;AADF;;ACcI;EDVJ;IAEI,oCAAA;EADF;AACF;ACQI;EDVJ;IAKI,oCAAA;EACF;AACF;AAAE;EACE,eAAA;EACA,eAAA;AAEJ;;AAGE;EACE,0BAAA;EACA,iBAAA;EACA,kBAAA;AAAJ;AAEE;EACE,iBAAA;EACA,WAAA;EACA,eAAA;EACA,gBAAA;EACA,0BAAA;AAAJ;;AAIA;EACE,kBAAA;EACA,WAAA;EACA,SAAA;EACA,cAAA;EACA,YAAA;EACA,eAAA;EACA,WAAA;AADF","sourcesContent":["@import './../../../theme/sass/abstracts/mixins.scss';\n\nion-menu::part(container) {\n  border-radius: 0 20px 20px 0;\n}\n\nion-menu {\n  @include breakpoint(sm, max) {\n    margin-top: env(safe-area-inset-top);    \n  }\n  @include breakpoint(sm, min) {\n    margin-top: env(safe-area-inset-top);\n  }\n  ion-icon {\n    font-size: 25px;\n    cursor: pointer;\n  }\n}\n\nion-header {\n  & ion-toolbar { \n    padding: 5px 10px 5px 10px;\n    --border-width: 0;\n    --background: #fff;\n  }\n  & ion-title{\n    padding: 0 10px 0;\n    width: auto;\n    font-size: 25px;\n    font-weight: 700;\n    color: rgba(0, 0, 0, 0.24);\n  }\n}\n\n.debug {\n  position: absolute;\n  bottom: 2px;\n  left: 6px;\n  z-index: 10000;\n  padding: 0px;\n  font-size: 10pt;\n  color: #888;\n}\n","$breakpoints: (\n  xxs: 375px,\n  xs: 450px,\n  sm: 576px,\n  md: 768px,\n  lg: 992px,\n  xl: 1200px,\n);\n\n// mobile-first breakpoint mixin\n@mixin breakpoint($breakpoint: md, $key: min, $orientation: false) {\n  @if ($orientation) {\n    @media (#{$key}-width: map-get($breakpoints, $breakpoint)) and (orientation : $orientation) {\n      @content;\n    }\n  } @else {\n    @media (#{$key}-width: map-get($breakpoints, $breakpoint)) {\n      @content;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
