import { Component, effect, } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { MasterDetailShowType } from '../master-detail/master-detail.component';
import { IInfoChapter, IInfoContent, ChapterType, ChapterTypeValues } from 'common_library'
import { HttpIoService } from 'src/app/services/communication/http-io.service';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MainStateService } from 'src/app/services/state/app-main-state.service';

@Component({
  selector: 'yf-app-info',
  templateUrl: './app-info.component.html',
  styleUrls: ['./app-info.component.scss'],
})
export class AppInfoComponent {

  masterDetailShow: MasterDetailShowType = 'master';
  compactMode: boolean;
  chapters$ = new BehaviorSubject<IInfoChapter[]>([]);
  selectedChapter: IInfoChapter;
  selectedChapterId: string;

  constructor(
    private modalCtrl: ModalController,
    private HIO: HttpIoService,
    private mainStateService: MainStateService,
    private translate: TranslateService
  ) {
    effect(() => {
      const res: IInfoContent = this.mainStateService.$cmsContent();
      if (res) {
        let infoChapters = res.infoChapters.filter(c => c.type !== ChapterType.PAYMENT_INSTRUCTIONS);
        infoChapters = infoChapters.sort((a, b) => this.sortChaptersByPriority(a, b));
        this.chapters$.next(this.formatSectionHtml(infoChapters));
        this.setSelectedChapter();
      }
    });
  }

  sortChaptersByPriority(a: IInfoChapter, b: IInfoChapter): number {
    const map = new Map<ChapterTypeValues, number>();
    map.set(ChapterType.PRIVACY, 0);
    map.set(ChapterType.TERMS_AND_CONDITIONS, 1);
    map.set(ChapterType.CONTACTS, 2);

    if (a.type && map.has(a.type)) {
      if (map.get(a.type) < map.get(b.type)) {
        return -1;
      }
      if (map.get(a.type) > map.get(b.type)) {
        return 1;
      }
    }
    else {
      return a.title.localeCompare(b.title)
    }
    return 0;
  }

  show(chapter: any) {
    this.selectedChapter = chapter;
    this.masterDetailShow = 'detail';
  }

  compactModeChange(compactMode: boolean) {
    this.compactMode = compactMode;
  }

  closeMe() {
    this.modalCtrl.dismiss();
  }

  backBtn() {
    if (this.compactMode)
      this.masterDetailShow = 'master'
    else
      this.closeMe()
  }

  private formatSectionHtml(chapters: IInfoChapter[]): IInfoChapter[] {
    if (!chapters || chapters.length === 0) return null;
    for (let i = 0; i < chapters.length; i++) {
      const sections: any[] = chapters[i].infoSections;
      for (let j = 0; j < sections.length; j++) {
        sections[j].html = sections[j].infoSectionVersion[0]?.description?.replace('<p>', '<ion-text>').replace('</p>', '</ion-text>');
      }
    }
    return chapters;
  }

  getImageUrl(fileName: string): string {
    return this.HIO.endPoint('multimedia/image/' + fileName);
  }

  setSelectedChapter() {
    if (!this.chapters$.getValue() || this.selectedChapter) return;
    if (this.selectedChapterId) this.selectedChapter = this.chapters$.getValue().find(el => el.id === this.selectedChapterId);
    if (this.selectedChapter && this.compactMode) {
      this.masterDetailShow = 'detail';
    } else if (!this.selectedChapter && !this.compactMode) {
      this.selectedChapter = this.chapters$.getValue()[0];
    }
  }
}
