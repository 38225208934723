import { Injectable, Signal, computed } from '@angular/core';
import { IButtonState } from '../../types/buttons/button-state.interface';
import { CPOs, OCPPComunication, State } from 'common_library';
import { MainStateService } from './app-main-state.service';

export interface ISendingCmds {
  state: boolean;
  connectorId: number;
}

@Injectable({
  providedIn: 'root',
})

export class ButtonStateService {
  $stationOnline = computed(() => this.mainStateService.$selectedStation()?.online);
  $baseCondition = computed(() => {
    return this.$stationOnline() && !this.mainStateService.$connectorCommandInProgress()
  });

  $buttonCancelReservation: Signal<IButtonState> = computed(() => {
    if (this.$baseCondition() &&
      this.mainStateService.$selectedConnector()?.state === State.RESERVED &&
      this.mainStateService.$selectedConnectorHasMineSession()) {
      return {
        show: true,
        disabled: false,
      }
    }
    return {
      show: false,
      disabled: false
    }
  });

  $buttonStartReservation: Signal<IButtonState> = computed(() => {
    if (this.mainStateService.$selectedStation().cpo === CPOs.FREETOX) {
      return {
        show: true,
        disabled: true,
      }
    }
    else if (this.$baseCondition()
      && this.mainStateService.$selectedConnector()?.state === State.AVAILABLE) {
      return {
        show: true,
        disabled: false,
      }
    }
    else {
      return {
        show: true,
        disabled: true,
      }
    }
  });

  $buttonStopCharge: Signal<IButtonState> = computed(() => {
    if (this.$baseCondition() &&
      this.mainStateService.$selectedConnector()?.state === State.CHARGING &&
      this.mainStateService.$selectedConnectorHasMineSession()) {
      return {
        show: true,
        disabled: false,
      }
    }
    return {
      show: false,
      disabled: false
    }
  });

  $buttonStartCharge: Signal<IButtonState> = computed(() => {
    // In caso ho una prenotazione controllo se è la mia e se è in stato reserved
    if (this.$baseCondition() &&
      this.mainStateService.$selectedConnector()?.state === State.RESERVED &&
      this.mainStateService.$selectedConnectorMineSession()?.state === State.RESERVED
    ) {
      return {
        show: true,
        disabled: false,
      }
    }
    // Se il connettore è disponibile posso iniziare e basta
    if (this.$baseCondition() &&
      this.mainStateService.$selectedConnector()?.state === State.AVAILABLE ||
      this.mainStateService.$selectedConnector()?.state === State.PREPARING) {
      return {
        show: true,
        disabled: false,
      }
    }
    return {
      show: true,
      disabled: true
    }
  });

  constructor(
    private mainStateService: MainStateService) { }
}
