// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.delete-profile-container {
  max-height: 80vh;
  padding: 24px;
}

.checkbox-square {
  --size: 20px;
  margin-right: 10px;
  min-width: 20px;
  min-height: 20px;
}

ion-checkbox::part(container) {
  border-radius: 6px;
  border: 2px solid #92949c;
}`, "",{"version":3,"sources":["webpack://./src/app/components/profile-deletion/profile-deletion.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,aAAA;AACF;;AAEA;EACE,YAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;AACF;;AACA;EACE,kBAAA;EACA,yBAAA;AAEF","sourcesContent":[".delete-profile-container {\n  max-height: 80vh;\n  padding: 24px;\n}\n\n.checkbox-square {\n  --size: 20px;\n  margin-right: 10px;\n  min-width: 20px;\n  min-height: 20px;\n}\nion-checkbox::part(container) {\n  border-radius: 6px;\n  border: 2px solid #92949c;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
