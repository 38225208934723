import { Component, EventEmitter, Inject, OnInit, Output, ViewChild, computed, signal } from '@angular/core';
import { RxState } from '@rx-angular/state';
import { BehaviorSubject, Observable } from 'rxjs';
import { GlobalState, GLOBAL_RX_STATE } from 'src/app/app.module';
import { HttpIoService } from 'src/app/services/communication/http-io.service';
import { MapPosition } from '../map/map.component';
import { StoreService } from 'src/app/services/utils/store.service';
import { _ } from 'src/app/consts';
import { TranslateService } from '@ngx-translate/core';
import { MainStateService } from 'src/app/services/state/app-main-state.service';

const URL = "https://nominatim.eagleprojects.cloud/search?q="
const TOLLERANCE = 0.1;
const LOCALSTORAGE_MAX_LENGTH = 10;

export type Search = { name: string, title: string, subtitle: string, position: MapPosition, icon: string }
export interface SearchResult { osm_id: number; place_id: number; type: string; boundigbox: string[], lat: string, lon: string, importance: number, display_name: string }
export type ResultType = "History" | "Search";

@Component({
  selector: 'yf-search-panel',
  templateUrl: './search-panel.component.html',
  styleUrls: ['./search-panel.component.scss'],
})
export class SearchPanelComponent implements OnInit {

  @ViewChild("ionSearchBar") ionSearchBar: HTMLIonSearchbarElement;
  @Output() showPosition = new EventEmitter<MapPosition>();
  @Output() isFocusedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  visible$: Observable<boolean>;
  value: string;


  $results = signal<{ type: ResultType, entities: Search[] }>({ type: "History", entities: [] });

  $height = computed(() => {
    let totalDimension = (this.$results().entities.length * 61);
    let items = this.$results().entities.length;
    return {
      height: items > 5 ? '330px' : `${totalDimension}px`,
      overflow: items > 5 ? "scroll" : "hidden"
    }
  });

  constructor(
    private HIO: HttpIoService,
    private storage: StoreService,
    private translate: TranslateService,
    public mainStateService: MainStateService
  ) { }

  ngOnInit() {
    this.$results.set({ type: "History", entities: this.storage.get<Array<Search>>(_.RECENT_SEARCHES) || [] });
  }

  async hideSearchbar() {
    this.mainStateService.setSearchPanelVisible(false);
    this.value = "";
  }

  async handleChange(evt: any) {
    await this.search(evt.detail.value);
  }

  async searchByKeyword(evt: any) {
    if (evt.type === "search") {
      await this.search(this.value.trim())
    }
  }

  async search(txt: string) {
    if (txt) {
      const rv = await this.HIO.externalGet<SearchResult[]>(`${URL}${txt}`);
      if (rv && rv.length > 0) {
        const bestResults = rv.filter(el => el.importance >= TOLLERANCE)
        if (bestResults.length > 0) {
          bestResults.sort((a, b) => b.importance - a.importance);

          let mappedResults = bestResults.map(searchElement => {
            console.log(searchElement);
            const cuttedSearch = searchElement.display_name.split(',')
            const firstElement = cuttedSearch.shift();
            let iconElement = "location-outline";
            switch (searchElement.type) {
              case "motorway_junction":
                iconElement = "car-outline";
                break;
              case "bus_stop":
                iconElement = "bus-outline";
                break;
              case "station":
                iconElement = "train-outline";
                break;
              case "supermarket":
                iconElement = "cart-outline";
                break;
            }
            let searchItem: Search = {
              name: searchElement.display_name,
              title: firstElement,
              subtitle: cuttedSearch.join(', '),
              position: {
                lat: parseFloat(searchElement.lat),
                lng: parseFloat(searchElement.lon),
                showLocationMarker: true,
              },
              icon: iconElement
            }
            return searchItem;
          })
          mappedResults = mappedResults.filter(
            (obj, index, self) =>
              index === self.findIndex(
                (t) => t.title === obj.title &&
                  t.name === obj.name &&
                  t.icon === obj.icon
              )
          );
          this.$results.set({ type: "Search", entities: mappedResults });
        } else {
          this.$results.set({ type: "Search", entities: [] });
        }
      } else {
        this.$results.set({ type: "Search", entities: [] });
      }
    }
    else {
      this.$results.set({ type: "History", entities: this.storage.get<Array<Search>>(_.RECENT_SEARCHES) || [] });
    }
  }


  /**
   *
   * @param search
   * Questo metodo viene chiamato dal template, all'ascolto di un evento del componente figlio recent-$searches
   */
  moveMapToResult(search: Search) {
    this.ionSearchBar.value = search.name;
    this.showPosition.emit({ lat: search.position.lat, lng: search.position.lng, showLocationMarker: search.position.showLocationMarker });
    this.mainStateService.setSearchPanelVisible(false);
    this.setNewRecentSearch(search);
  }

  /**
   *
   * @param search
   * Questo metodo viene usato per gestire il localstorage delle ricerche recenti durante la ricerca
   */
  setNewRecentSearch(search: Search) {
    let localStorageSearches: Search[] = [];

    if (this.storage.get<Array<Search>>(_.RECENT_SEARCHES)) {
      localStorageSearches = this.storage.get<Array<Search>>(_.RECENT_SEARCHES);
    }

    // controllo se l'elemento esiste già nella cronologia
    const idxExistingItem = localStorageSearches.findIndex(el => el.name === search.name);;

    if (idxExistingItem !== -1)
      localStorageSearches.splice(idxExistingItem, 1);

    if (localStorageSearches.length >= LOCALSTORAGE_MAX_LENGTH) {
      localStorageSearches.pop();
      localStorageSearches.unshift(search);
    } else {
      localStorageSearches.unshift(search);
    }

    this.storage.set(_.RECENT_SEARCHES, localStorageSearches);
  }

  removeRecentSearch(i: number) {
    let localStorageSearches = this.storage.get<Array<Search>>(_.RECENT_SEARCHES);
    localStorageSearches.splice(i, 1);
    this.storage.set(_.RECENT_SEARCHES, localStorageSearches)
    this.$results.set({ type: "History", entities: localStorageSearches || [] });
  }

  trackByFn(i: number) {
    return i;
  }
}
