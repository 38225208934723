import { Component, EventEmitter, Output, computed, input } from '@angular/core';
import { IConnector, OCPICdrDimensionType, TariffUtil } from 'common_library';
import { MainStateService } from 'src/app/services/state/app-main-state.service';

export interface ConnectorUI extends IConnector {
  isDisabled: boolean;
  stateLabelLoc: string;
  classes: any;
}

@Component({
  selector: 'yf-station-connector',
  templateUrl: './station-connector.component.html',
  styleUrls: ['./station-connector.component.scss'],
})
export class StationConnectorComponent {
  $connector = input<ConnectorUI>(null);
  @Output() connectorButtonEvent = new EventEmitter<number>();

  constructor(
    private mainStateService: MainStateService) { }

  $connectorPrice = computed(() => {
    let tariff = this.mainStateService.$selectedConnectorMineSession()?.tariffJson || this.$connector()?.tariff;
    if (tariff) {
      const connectorPower = TariffUtil.getConnectorPower(this.$connector());
      const costs = TariffUtil.calculateCosts(0, 1000, 0, tariff, connectorPower, true);
      const energyCost = costs.find(c => c.type == OCPICdrDimensionType.ENERGY);
      return energyCost.price.inclVat;
    }
    return null;
  })

  $connectorPower = computed(() => {
    return TariffUtil.getConnectorPower(this.$connector());
  })

  selectConnector() {
    this.mainStateService.setSelectedConnector(this.$connector())
  }
}
