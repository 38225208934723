import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { FORMLY_CONFIG, FormlyModule } from '@ngx-formly/core';
import { FormlyIonicModule } from '@ngx-formly/ionic';
import { FormlyCheckboxComponent } from 'src/app/my-formly/components/formly-checkbox/formly-checkbox.component';

import { ComponentsModule } from '../components/components.module';
import { FormlyInputComponent } from '../my-formly/components/formly-input/formly-input.component';
import { FormlySelectComponent } from '../my-formly/components/formly-select.ts/formly-select.component';
import { FormlyTextareaComponent } from '../my-formly/components/formly-textarea/formly-textarea.component';
import { MyFormlyHelpers } from '../my-formly/my-formly.helpers';
import { MyFormlyModule } from '../my-formly/my-formly.module';
import { CarPage } from './car/car.page';
import { EditProfilePage } from './edit-profile/edit-profile.page';
import { FilterPage } from './filter/filter.page';
import { LoginPage } from './login/login.page';
import { ReviewsPage } from './reviews/reviews.page';
import { UserProfilePage } from './user-profile/user-profile.page';
import { LoginFormTemplateComponent } from './login/login-form-template/login-form-template.component';
import { SessionToastComponent } from './session-toast/session-toast.component';
import { InstallationComponent } from './installation/installation.component';
import { ClusterComponent } from './cluster/cluster.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

const pages_components = [LoginFormTemplateComponent];

const pages = [
  LoginPage,
  UserProfilePage,
  EditProfilePage,
  CarPage,
  FilterPage,
  ReviewsPage,
  SessionToastComponent,
  InstallationComponent,
  ClusterComponent,
];

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    ComponentsModule,
    MyFormlyModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyIonicModule,
    FormlyModule.forRoot({
      validators: [{ name: 'fieldMatch', validation: MyFormlyHelpers.PasswordMatchValidator }],
      validationMessages: [
        { name: 'email', message: MyFormlyHelpers.emailValidationMessage },
        { name: 'minlength', message: MyFormlyHelpers.minlengthValidationMessages },
      ],
      extras: { lazyRender: true },
      types: [
        { name: 'f-input', component: FormlyInputComponent },
        { name: 'f-select', component: FormlySelectComponent },
        { name: 'f-checkbox', component: FormlyCheckboxComponent },
        { name: 'f-textarea', component: FormlyTextareaComponent },
      ],
    }),
    TranslateModule.forChild({ extend: true, isolate: false }),
  ],
  providers: [{ provide: FORMLY_CONFIG, multi: true, useFactory: MyFormlyHelpers.formlyValidationConfig, deps: [TranslateService] }],
  declarations: [...pages, ...pages_components],
  exports: [...pages],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PagesModule { }
