import { Component } from '@angular/core';

@Component({
  selector: 'yf-divider',
  template: `
  <div class="divider"></div>
  `,
  styles: [`
  .divider {
    margin: 8px;
    border-top-width: 2px;
    border-top-style: solid;
    border-color: #d5d5d5;
  }
  `]
})
export class DividerComponent {
  constructor() { }
}
