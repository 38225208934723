import { Injectable, ElementRef } from '@angular/core';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { PushNotificationsNativeService } from './native/push-notifications-native.service';
import { GeoLocationNativeService } from './native/geo-location-native.service';
import { Keyboard, KeyboardResize, KeyboardResizeOptions } from '@capacitor/keyboard';
import { AppUpdate, AppUpdateAvailability } from '@capawesome/capacitor-app-update';
import { MainStateService } from './state/app-main-state.service';
import { PlatformType } from 'common_library';

@Injectable({
  providedIn: 'root',
})

export class AppNativeService {
  constructor(
    public geolocationNativeService: GeoLocationNativeService,
    public mainStateService: MainStateService,
    public pushNotificationNativeService: PushNotificationsNativeService,
    private router: Router,
    private zone: NgZone
  ) { }

  async init() {
    if (this.mainStateService.getNative()) {
      await this.geolocationNativeService.requestPermissions();
    }
  }

  async getCurrentAppVersionCode() {
    const result = await AppUpdate.getAppUpdateInfo();
    return result.currentVersionCode;
  };

  async getCurrentAppVersionName() {
    const result = await AppUpdate.getAppUpdateInfo();
    return result.currentVersionName;
  };

  async startUpdate() {
    if (this.mainStateService.getPlatformType() === PlatformType.AndroidNative) {
      await this.performImmediateUpdate();
    }
    else if (this.mainStateService.getPlatformType() === PlatformType.iOSNative) {
      await this.openAppStore();
    }
  }

  async performImmediateUpdate() {
    const result = await AppUpdate.getAppUpdateInfo();
    if (result.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE) {
      return;
    }
    if (result.immediateUpdateAllowed) {
      await AppUpdate.performImmediateUpdate();
    }
  };

  async openAppStore() {
    await AppUpdate.openAppStore();
  };


  listener() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        // Example url: https://beerswift.app/tabs/tab2
        // slug = /tabs/tab2
        const slug = event.url.split(".app").pop();
        if (slug) {
          this.router.navigateByUrl(slug);
        }
        // If no match, do nothing - let regular routing
        // logic take over
      });
    });
  }

  resizeKeyboad(mode: KeyboardResize) {
    let options: KeyboardResizeOptions = { mode: mode }
    Keyboard.setResizeMode(options)
  }

  resizeKeyboardOnFullScrean(visibile: boolean) {
    Keyboard.setAccessoryBarVisible({ isVisible: visibile })
  }

}
