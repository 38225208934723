import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CU, ICar, ICarBrand, ICarModel, IUpdateCarDTO, PlugType, PlugTypeValues } from 'common_library';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';
import { MyFormlyHelpers } from 'src/app/my-formly/my-formly.helpers';
import { AlertService } from 'src/app/services/utils/alert.service';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from '../../services/auth/auth.service';
import { CarService } from '../../services/entities/car.service';
import { ToastService } from 'src/app/services/utils/toast.service';
import { TranslateService } from '@ngx-translate/core';

export declare type ICarFields = {
  // name: string;
  modelId: number;
  brandId: number;
  plugTypes: PlugTypeValues[];
};

@Component({
  selector: 'app-car',
  templateUrl: './car.page.html',
  styleUrls: ['./car.page.scss'],
})
export class CarPage implements OnInit {
  carFrm: MyFormlyHelpers.FormlyForm<ICarFields>;
  // carModel: ICarFields = { name: null, brandId: null, modelId: null, plugTypes: [] };
  carModel: ICarFields = { brandId: null, modelId: null, plugTypes: [] };
  // options: FormlyFormOptions = {};

  constructor(
    public modalCtrl: ModalController,
    private http: HttpClient,
    private auth: AuthService,
    private toast: ToastService,
    private app: AppService,
    private alert: AlertService,
    public carService: CarService,
    private translate: TranslateService
  ) {

  }

  getCarBrands(): Observable<ICarBrand[]> {
    return this.http.get<ICarBrand[]>(`${this.app.apiUrl}/car-brand`).pipe(take(1));
  }

  getCarModels(brandId: number): Observable<ICarModel[]> {
    return this.http.get<ICarModel[]>(`${this.app.apiUrl}/car-model/${brandId}`).pipe(take(1));
  }

  brands$ = new BehaviorSubject<ICarBrand[]>([]);
  models$ = new BehaviorSubject<ICarModel[]>([]);

  isLoading = false;

  @Input() car: ICar;

  async ngOnInit() {
    this.isLoading = true;

    const brands = await this.http.get<ICarBrand[]>(`${this.app.apiUrl}/car-brand`).toPromise();
    this.brands$.next(brands);

    await this.formGenerate();
    this.isLoading = false;
  }

  async formGenerate() {
    if (this.car) {

      const models = await this.http
        .get<ICarModel[]>(`${this.app.apiUrl}/car-model/${this.car.model.brand.id}`)
        .toPromise();
      this.models$.next(models);

      this.carModel = {
        // name: this.car.name,
        brandId: this.car.model.brand.id,
        modelId: this.car.model.id,
        plugTypes: this.car.plugTypes,
      };
    }

    this.carFrm = new MyFormlyHelpers.FormlyForm<any>(this.carModel, [
      // MyFormlyHelpers.inputField('name', 'Nome Veicolo'),
      MyFormlyHelpers.selectField('brandId', this.translate.instant("CAR.CAR_DETAIL.VEHICLE_MODEL"), {
        multiple: false,
        options: this.brands$,
        onChange: async (brandId) => {
          const models = await this.http
            .get<ICarModel[]>(`${this.app.apiUrl}/car-model/${brandId}`)
            .toPromise();
          this.models$.next(models);
          this.carFrm.form.get('modelId').patchValue(null);
        },
      }),
      MyFormlyHelpers.selectField(
        'modelId',
        this.translate.instant("CAR.CAR_DETAIL.VEHICLE_MODEL"),
        { multiple: false, options: this.models$ },
        { 'templateOptions.disabled': () => !this.carFrm.form.get('brandId').valid }
      ),
      MyFormlyHelpers.selectField('plugTypes', this.translate.instant("APP.FILTER.CONNECTORS"), {
        multiple: true,
        options: of(CU.ToArray(PlugType)),
      }),
    ]);
    this.isLoading = false;
  }

  async addCar() {
    if (!this.carFrm.form.valid) {
      return;
    }

    try {
      await this.carService.add(
        // this.carFrm.model.name,
        +this.carFrm.model.modelId,
        this.carFrm.model.plugTypes
      );
      this.toast.presentBasicToast({ message: this.translate.instant("CAR.CAR_EDIT.NEW_CAR_ADDED"), color: 'secondary', icon: 'car-outline' });
    } catch (err) {
      this.toast.presentBasicToast({ message: this.translate.instant("CAR.CAR_EDIT.CANT_ADD_NEW_CAR"), color: 'danger', icon: 'warning' });
    }
    // this.cars = this.carService.myCars$.getValue();
    await this.formGenerate();
    // this.viewAddCar = false;
    this.closeMe();
  }

  async updateCar(car) {
    if (!this.carFrm.form.valid) {
      return;
    }

    try {
      const dto: IUpdateCarDTO = {
        modelId: +this.carFrm.model.modelId,
        plugTypes: this.carFrm.model.plugTypes,
      };
      await this.carService.update(car.id, dto);
      this.toast.presentBasicToast({ message: this.translate.instant("CAR.CAR_EDIT.CAR_UPDATED"), color: 'secondary', icon: 'car-outline' });
    } catch (err) {
      this.toast.presentBasicToast({ message: this.translate.instant("CAR.CAR_EDIT.CANT_UPDATE_CAR"), color: 'danger', icon: 'warning' });
    }
    // this.cars = this.carService.myCars$.getValue();
    await this.formGenerate();
    // this.viewAddCar = false;
    this.closeMe();
  }

  async deleteCar() {
    this.alert.presentYfAlert({
      header: this.translate.instant("PHRASE.REMOVE_CAR"),
      message: `Vuoi rimuovere la macchina "${this.car.model.brand.name} - ${this.car.model.name}"?`,
      buttons: [
        {
          text: 'OK',
          handler: async () => {
            try {
              const deleted = await this.carService.delete(this.car.id);
              if (deleted) {
                this.toast.presentBasicToast({ message: this.translate.instant("CAR.CAR_EDIT.CAR_DELETED"), color: 'secondary', icon: 'car-outline' });
              } else {
                this.toast.presentBasicToast({ message: this.translate.instant("CAR.CAR_EDIT.CANT_DELETE_CAR"), color: 'danger', icon: 'warning' });
              }
            } catch (err) {
              this.toast.presentBasicToast({ message: this.translate.instant("CAR.CAR_EDIT.CANT_DELETE_CAR"), color: 'danger', icon: 'warning' });
            }
            // this.cars = this.carService.myCars$.getValue();
            this.car = null;
            await this.formGenerate();
            // this.viewAddCar = false;
            this.closeMe();
          },
        },
        { text: this.translate.instant("BUTTONS.CANCEL") },
      ],
    });
  }

  async addNewCar() {
    await this.formGenerate();
    // this.viewAddCar = true;
  }
  // cancel() {
  //   this.viewAddCar = false;
  // }

  async closeMe() {
    await this.modalCtrl.dismiss();
  }
}
