import { Component, EventEmitter, Input, Output, computed } from '@angular/core';
import { getStateLabelByNumber, OCPPComunication, State, StateValues } from 'common_library';
import { ConnectorUI } from '../station-connector/station-connector.component';
import { TranslateService } from '@ngx-translate/core';
import { MainStateService } from 'src/app/services/state/app-main-state.service';

@Component({
  selector: 'yf-station-connector-selector',
  templateUrl: './station-connector-selector.component.html',
  styleUrls: ['./station-connector-selector.component.scss'],
})
export class StationConnectorSelectorComponent {
  @Input() mayWrap: 'wrap' | undefined;
  @Output() changeSelection = new EventEmitter<number>();

  $connectors = computed(() => {
    let selectedStation = this.mainStateService.$selectedStation();
    let selectedConnector = this.mainStateService.$selectedConnector();

    if (selectedStation) {
      return selectedStation.connectors.sort((a, b) => a.name?.localeCompare(b.name)).map((c) => {
        let isSelected = c.id === selectedConnector?.id;
        let mineSession = this.mainStateService.$sessions()?.find(s => s.connectorId === c.id);
        let hasMineSession = !!mineSession;
        let isDisabled = !this.mainStateService.$selectedStation()?.online || (c.state !== State.AVAILABLE && c.state !== State.PREPARING && !hasMineSession);
        let state = hasMineSession ? mineSession.state : c.state;

        let stateLabelLoc = this.translate.instant('CONNECTOR_STATES.' + getStateLabelByNumber(state));
        if (c.innerState === OCPPComunication.InnerState.Preparing || c.innerState === OCPPComunication.InnerState.Faulted) {
          stateLabelLoc = this.translate.instant('CONNECTOR_STATES.' + c.innerState?.trim().toUpperCase());
        }

        let classes: any = {
          setOpacity: !isSelected
        }

        classes = { ...classes, ...this.getButtonClass(state, hasMineSession, this.mainStateService.$selectedStation()?.online) };
        console.log(`Connector ${c?.name || c.id} -> classes: ${JSON.stringify(classes)}, isDisabled: ${isDisabled}, hasMineSession: ${hasMineSession}, state: ${state} (sessionState: ${mineSession?.state}, connectorState)`);
        return { ...c, isDisabled, stateLabelLoc, classes };
      });
    } else {
      return [];
    }
  });

  constructor(
    private translate: TranslateService,
    public mainStateService: MainStateService
  ) { }

  connectorTrackBy(index: number, connector: ConnectorUI) {
    return connector.id;
  }

  getButtonClass(state: StateValues, hasMineSession: boolean, stationOnline: boolean): { [key: string]: boolean } {
    if (hasMineSession) {
      switch (state) {
        case State.RESERVED:
          return { reserved: true };
        case State.CHARGING:
          return { charging: true };
        case State.OCCUPIED:
          return { parking: true };
      }
    }
    else {
      if (!stationOnline || (state === State.RESERVED || state === State.CHARGING || state === State.OCCUPIED)) {
        return { white: true };
      }
    }
    return { primary: true };
  }
}
